/**
 * IMPORTS
 */

import React from 'react';
import { FontAwesomeIcon as FA } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

/**
 * CORE
 */

const Loading = () => (
  <FA
    className="app-spinner"
    icon={faSpinner}
    size="4x"
    pulse
    fixedWidth
  />
);

export default Loading;
