/**
 * IMPORTS
 */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { attemptAction } from '../actions/queue-actions';
import {
  getAttemptableQueuedItems,
  getIdleQueuedItemsCount,
  getRunningQueuedItemsCount,
} from '../selectors';

/**
 * CORE
 */

class ActionQueue extends Component {
  componentDidMount() {
    const { queuedItems } = this.props;
    queuedItems.forEach(({ itemId }) => this.props.attemptAction(itemId));
  }

  componentWillReceiveProps({ queuedItems }) {
    if (queuedItems !== this.props.queuedItems) {
      queuedItems.forEach(({ itemId }) => this.props.attemptAction(itemId));
    }
  }

  render() {
    const { runningItemsCount, idleItemsCount } = this.props;
    if (runningItemsCount === 0 && idleItemsCount === 0) {
      return null;
    }

    return (
      <div
        style={{
          position: 'fixed',
          bottom: 0,
          left: 0,
          visibility: 'hidden',
        }}
      >
        {runningItemsCount + idleItemsCount}
      </div>
    );
  }
}

ActionQueue.propTypes = {
  queuedItems: PropTypes.array.isRequired,
  idleItemsCount: PropTypes.number.isRequired,
  runningItemsCount: PropTypes.number.isRequired,
  attemptAction: PropTypes.func.isRequired,
};

export default connect(
  state => ({
    idleItemsCount: getIdleQueuedItemsCount(state),
    runningItemsCount: getRunningQueuedItemsCount(state),
    queuedItems: getAttemptableQueuedItems(state),
  }),
  { attemptAction },
)(ActionQueue);
