/* global window */

/**
 * IMPORTS
 */

import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';
import { FontAwesomeIcon as FA } from '@fortawesome/react-fontawesome';
import { faRedoAlt, faCamera } from '@fortawesome/free-solid-svg-icons';
import { faTimesCircle } from '@fortawesome/free-regular-svg-icons';
import { Translate } from 'react-i18nify';
import ReactInterval from 'react-interval-timer';

import {
  addSelfie,
} from '../../actions/session-actions';
import {
  completeStep,
  cancelStep,
} from '../../actions/nav-actions';
import DeviceOrientation, { Orientation } from '../Orientation';
import Camera from '../Camera';
import Loading from './Loading';

import '../../css/rotate-device.scss';
import '../../css/selfie-video.scss';
import '../../css/selfie-flash.scss';
import { getStepDisabled } from '../../selectors';

/**
 * ROTATE DEVICE
 */

const RotateDevice = () => (
  <div className="overlay">
    <div className="iconContainer">
      <div className="phone">
        <FA icon={faRedoAlt} />
      </div>

      <Translate value="Selfie.rotate" className="rotate-text" />
    </div>
  </div>
);

/**
 * CORE
 */

class Selfie extends Component {
  static stepId = 'selfie';

  static propTypes = {
    disabled: PropTypes.bool,
    triggerWithBack: PropTypes.bool, // used for selfie stick
    completeStep: PropTypes.func.isRequired,
    cancelStep: PropTypes.func.isRequired,
    addSelfie: PropTypes.func.isRequired,
  };

  static defaultProps = {
    disabled: false,
    triggerWithBack: true, // used for selfie stick
  };

  constructor(props) {
    super(props);

    this.state = {
      hasTriggered: false,
      showFlash: false,
      count: 0,
    };

    this.onTriggerClick = this.onTriggerClick.bind(this);
    this.onCancelClick = this.onCancelClick.bind(this);
    this.onTick = this.onTick.bind(this);
    this.onPhoto = this.onPhoto.bind(this);
    this.onDeviceBack = this.onDeviceBack.bind(this);
  }

  componentDidMount() {
    const { triggerWithBack } = this.props;
    if (triggerWithBack) {
      const { location, history } = window;
      location.hash = '#no-back';
      history.pushState(null, '', '#stay');
      window.addEventListener('hashchange', this.onDeviceBack);
    }
  }

  componentWillUnmount() {
    const { triggerWithBack } = this.props;
    if (triggerWithBack) {
      window.removeEventListener('hashchange', this.onDeviceBack);
    }
  }

  onDeviceBack() {
    const { location, history } = window;
    if (location.hash === '#no-back') {
      history.pushState(null, '', '#stay');
      this.onTriggerClick();
    }
  }

  onTick() {
    this.takePhoto();
  }

  onPhoto(blob) {
    const { count } = this.state;
    this.props.addSelfie(blob);
    this.setState({
      showFlash: false,
      count: count + 1,
    });
  }

  onTriggerClick(event = null) {
    if (event) {
      event.preventDefault();
    }

    if (this.state.hasTriggered) {
      return;
    }

    this.takePhoto();

    this.setState(
      { hasTriggered: true },
      () => this.props.completeStep(Selfie.stepId),
    );
  }

  onCancelClick(event) {
    if (event) {
      event.preventDefault();
    }

    this.props.cancelStep(Selfie.stepId);
  }

  takePhoto() {
    this.setState(
      { showFlash: true },
      () => this.camera.takePhoto(),
    );
  }

  render() {
    const { disabled } = this.props;
    const { hasTriggered, showFlash, count } = this.state;

    if (count === 5) {
      return <Loading />;
    }

    return (
      <DeviceOrientation lockOrientation={hasTriggered ? 'landscape' : undefined}>
        <Orientation
          orientation="landscape"
          alwaysRender={false}
        >
          {/* we don't disable interval when step is disabled
              so that we keep taking selfies until interval is unmounted */}
          <ReactInterval
            timeout={1500}
            enabled={hasTriggered && count < 5 && !showFlash}
            callback={this.onTick}
          />
          <div className={`flash ${showFlash ? 'is-visible' : 'is-hidden'}`} />
          <div className="liveview-container" style={{ width: '100vw' }}>
            <Camera
              ref={(cam) => { this.camera = cam; }}
              onError={error => window.alert(error.message || String(error))}
              onTakePhoto={this.onPhoto}
              onGrabFrame={this.onPhoto}
            />
          </div>
          <div
            style={{
              position: 'absolute',
              right: '2rem',
              top: '50%',
              transform: 'translateY(-50%)',
            }}
          >
            <Button
              color="primary"
              onClick={this.onTriggerClick}
              disabled={disabled}
              style={{
                padding: '1.25rem',
                lineHeight: 0,
                borderRadius: '50%',
                boxShadow: '0 0 0.5rem black',
              }}
            >
              <FA
                icon={faCamera}
                fixedWidth={false}
                size="3x"
              />
            </Button>
          </div>
          <div
            style={{
              position: 'absolute',
              left: '1rem',
              top: '1rem',
            }}
          >
            <FA
              icon={faTimesCircle}
              size="2x"
              color="rgba(0,0,0,0.75)"
              onClick={this.onCancelClick}
              disabled={disabled}
            />
          </div>
        </Orientation>
        <Orientation orientation="portrait">
          <RotateDevice />
        </Orientation>
      </DeviceOrientation>
    );
  }
}

export default connect(
  state => ({
    disabled: getStepDisabled(state),
  }),
  {
    addSelfie,
    completeStep,
    cancelStep,
  },
)(Selfie);
