/**
 * IMPORTS
 */

const { v4: uuid } = require('uuid');

/**
 * SYNC
 */

export const setLocale = locale => (
  {
    type: 'SET_LOCALE',
    locale,
  }
);

/**
 * ASYNC
 */

export const initializeClient = appId => (
  {
    type: 'INITIALIZE_CLIENT_REQUEST',
    appId,
    requestId: uuid(),
  }
);

export const checkOnline = (timeout = 5000) => (
  {
    type: 'ONLINE_REQUEST',
    timeout,
    requestId: uuid(),
  }
);
