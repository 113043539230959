/**
 * SYNC
 */

export const showAsyncError = (asyncRequestType, error) => (
  {
    type: 'SHOW_ASYNC_ERROR',
    asyncRequestType,
    error,
  }
);

export const hideAsyncError = () => (
  {
    type: 'HIDE_ASYNC_ERROR',
  }
);

export const retryAsync = () => (
  {
    type: 'RETRY_ASYNC',
  }
);

export const cancelAsync = () => (
  {
    type: 'CANCEL_ASYNC',
  }
);
