/**
 * IMPORTS
 */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import { I18n, Translate } from 'react-i18nify';
import parseRegex from 'regex-parser';
import {
  FormGroup,
  Input,
  Label,
  FormFeedback,
} from 'reactstrap';

/**
 * CORE
 */

const TextInputComponent = ({
  input,
  meta: { touched, error },
  i18nPath,
  disabled,
  ...attributes
}) => {
  i18nPath = typeof i18nPath === 'string' ? i18nPath : i18nPath.join('.');
  const prefix = i18nPath ? `${i18nPath}.` : '';
  return (
    <FormGroup>
      <Label for={input.name}>
        <Translate value={`${prefix}${input.name}.label`} />
      </Label>
      <Input
        id={input.name}
        invalid={touched && !!error}
        type="text"
        readOnly={disabled}
        placeholder={I18n.t(`${prefix}${input.name}.placeholder`)}
        {...input}
        {...attributes}
      />
      <FormFeedback>
        <Translate value={`${prefix}${input.name}.${error}`} />
      </FormFeedback>
    </FormGroup>
  );
};

TextInputComponent.propTypes = {
  input: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired,
  disabled: PropTypes.bool,
  i18nPath: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array,
  ]),
  regexp: PropTypes.string,
};

TextInputComponent.defaultProps = {
  i18nPath: '',
  disabled: false,
  regexp: null,
};

class TextInput extends Component {
  constructor(props) {
    super(props);
    this.validators = [
      this.validateRequired.bind(this),
      this.validateMinLength.bind(this),
      this.validateMaxLength.bind(this),
      this.validateRegExp.bind(this),
    ];
  }

  validateRequired(v) {
    const { required } = this.props;
    return (!required || v ? undefined : 'required');
  }

  validateMinLength(v) {
    const { minlength } = this.props;
    return (Number.isNaN(minlength) || !v || v.length >= minlength ? undefined : 'invalid');
  }

  validateMaxLength(v) {
    const { maxlength } = this.props;
    return (Number.isNaN(maxlength) || !v || v.length <= maxlength ? undefined : 'invalid');
  }

  validateRegExp(v) {
    const { regexp } = this.props;
    try {
      const regExp = parseRegex(regexp);
      return regExp.test(v) ? undefined : 'invalid';
    } catch (err) {
      return undefined;
    }
  }

  render() {
    const { required, minlength, maxlength, regexp, ...props } = this.props;
    return (
      <Field
        component={TextInputComponent}
        validate={this.validators}
        {...props}
      />
    );
  }
}

TextInput.propTypes = {
  required: PropTypes.bool,
  minlength: PropTypes.number,
  maxlength: PropTypes.number,
  regexp: PropTypes.string,
};

TextInput.defaultProps = {
  required: false,
  minlength: NaN,
  maxlength: NaN,
  regexp: null,
};

export default TextInput;
