/**
 * IMPORTS
 */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Field, FieldArray } from 'redux-form';
import { I18n, Translate } from 'react-i18nify';
import { isEmail } from 'validator';
import { FontAwesomeIcon as FA } from '@fortawesome/react-fontawesome';
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import {
  FormGroup,
  Input,
  InputGroup,
  Button,
  InputGroupAddon,
  FormFeedback,
  Label,
} from 'reactstrap';

/**
 * CORE
 */

const validEmailFn = v => (v && !isEmail(v) ? 'invalid' : undefined);

const EmailInputItemComponent = ({
  input,
  meta: { error },
  disabled,
  onRemove,
  prefix,
  ...attributes
}) => (
  <FormGroup>
    <InputGroup>
      <Input
        id={input.name}
        type="email"
        placeholder={I18n.t(`${prefix}.placeholder`)}
        invalid={!!error}
        readOnly={disabled}
        {...input}
        {...attributes}
      />
      <InputGroupAddon addonType="append">
        <Button
          color={error ? 'danger' : 'secondary'}
          onClick={() => onRemove()}
          disabled={disabled}
        >
          <FA
            icon={faTrashAlt}
            fixedWidth
          />
        </Button>
      </InputGroupAddon>
      <FormFeedback>
        <Translate value={`${prefix}.${error}`} />
      </FormFeedback>
    </InputGroup>
  </FormGroup>
);

EmailInputItemComponent.propTypes = {
  input: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired,
  disabled: PropTypes.bool,
  onRemove: PropTypes.func.isRequired,
  prefix: PropTypes.string,
};

EmailInputItemComponent.defaultProps = {
  prefix: '',
  disabled: false,
};

class EmailInputListComponent extends Component {
  constructor(props) {
    super(props);

    this.newInput = null;
  }

  render() {
    const {
      fields,
      meta: { error, submitFailed, dirty },
      i18nPath,
      disabled,
    } = this.props;

    const i18nActualPath = typeof i18nPath === 'string' ? i18nPath : i18nPath.join('.');
    const prefix = i18nActualPath ? `${i18nActualPath}.` : '';
    const handleAdd = (ref, fds) => {
      if (ref.value) {
        fds.push(ref.value);
        ref.value = '';
      }
    };

    return (
      <div>
        <Label>
          <Translate value={`${prefix}${fields.name}.label`} />
        </Label>
        {fields.map((name, index) => (
          <Field
            key={index.toString()}
            name={name}
            disabled={disabled}
            prefix={`${prefix}${fields.name}`}
            component={EmailInputItemComponent}
            validate={[validEmailFn]}
            onRemove={() => fields.remove(index)}
            onChange={({ target }) => {
              if (!target.value) {
                setTimeout(() => fields.remove(index), 0);
              }
            }}
          />
        ))}
        <FormGroup>
          <Input
            type="email"
            invalid={(submitFailed || dirty) && !!error}
            placeholder={I18n.t(`${prefix}${fields.name}.placeholder`)}
            readOnly={disabled}
            innerRef={(i) => { this.newInput = i; }}
            onBlur={({ target }) => handleAdd(target, fields)}
            onKeyPress={(event) => {
              const { target, key } = event;
              if (key === 'Enter') {
                event.preventDefault();
                handleAdd(target, fields);
                setTimeout(() => { this.newInput.focus(); }, 0);
              }
            }}
          />
          <FormFeedback>
            <Translate value={`${prefix}${fields.name}.${error}`} />
          </FormFeedback>
        </FormGroup>
      </div>
    );
  }
}

EmailInputListComponent.propTypes = {
  fields: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired,
  disabled: PropTypes.bool,
  i18nPath: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array,
  ]),
};

EmailInputListComponent.defaultProps = {
  i18nPath: '',
  disabled: false,
};

/* eslint-disable-next-line react/no-multi-comp */
class MultipleEmailInput extends Component {
  constructor(props) {
    super(props);
    this.validators = [
      this.validateRequiredArray.bind(this),
    ];
  }

  validateRequiredArray(v) {
    const { required } = this.props;
    return !required || (v && v.length) ? undefined : 'required';
  }

  render() {
    const { required, ...props } = this.props;
    return (
      <FieldArray
        component={EmailInputListComponent}
        validate={this.validators}
        {...props}
      />
    );
  }
}

MultipleEmailInput.propTypes = {
  required: PropTypes.bool,
};

MultipleEmailInput.defaultProps = {
  required: false,
};

export default MultipleEmailInput;
