/* global document */

/**
 * IMPORTS
 */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Field, reduxForm } from 'redux-form';
import { decode } from 'querystring';
import { FontAwesomeIcon as FA } from '@fortawesome/react-fontawesome';
import { faQrcode, faKeyboard, faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';
import { I18n, Translate } from 'react-i18nify';
import {
  Card,
  CardBody,
  Button,
  Form,
  FormGroup,
  Label,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
} from 'reactstrap';

import {
  completeStep,
  cancelStep,
} from '../../actions/nav-actions';
import {
  addSessionData,
} from '../../actions/session-actions';
import { getStepDisabled, getFormInitialValues } from '../../selectors';
import BarcodeReader from '../BarcodeReader';

/**
 * UTILS
 */

const required = value => (value ? undefined : I18n.t('Scan.required'));
const min5 = value => (value && value.length >= 5 ? undefined : I18n.t('Scan.tooShort'));

const getCodeFromUrl = (url) => {
  const parser = document.createElement('a');
  parser.href = url;
  const query = decode(parser.search.substr(1));
  return query.code || null;
};

/**
 * CODE INPUT
 */

const CodeInput = () => (
  <FormGroup>
    <Label>
      <Translate value="Scan.code" />
    </Label>
    <InputGroup>
      <InputGroupAddon addonType="prepend">
        <InputGroupText>
          <FA icon={faQrcode} />
        </InputGroupText>
      </InputGroupAddon>
      <Field
        className="form-control form-control-lg"
        name="code"
        component="input"
        type="text"
        placeholder={I18n.t('Scan.codePlaceholder')}
        validate={[required, min5]}
        autoFocus
      />
    </InputGroup>
  </FormGroup>
);

/**
 * CORE
 */

class Scan extends Component {
  static stepId = 'scan';

  static propTypes = {
    disabled: PropTypes.bool,
    submitting: PropTypes.bool,
    valid: PropTypes.bool,
    handleSubmit: PropTypes.func.isRequired,
    addSessionData: PropTypes.func.isRequired,
    completeStep: PropTypes.func.isRequired,
    cancelStep: PropTypes.func.isRequired,
  };

  static defaultProps = {
    disabled: false,
    submitting: false,
    valid: false,
  };

  constructor(props) {
    super(props);

    this.state = {
      isScan: true,
    };

    this.onSubmit = this.onSubmit.bind(this);
    this.onCancel = this.onCancel.bind(this);
    this.onScan = this.onScan.bind(this);
    this.onScanError = this.onScanError.bind(this);
    this.onModeSwitch = this.onModeSwitch.bind(this);
  }

  onSubmit({ code }) {
    this.props.addSessionData({ code: code.toUpperCase() });
    this.props.completeStep(Scan.stepId);
  }

  onCancel(event) {
    if (event) {
      event.preventDefault();
    }

    this.props.cancelStep(Scan.stepId);
  }

  onModeSwitch(e) {
    e.preventDefault();
    const { isScan } = this.state;
    this.setState({ isScan: !isScan });
  }

  onScan({ text: value }) {
    const { disabled } = this.props;
    if (value && !disabled) {
      const code = getCodeFromUrl(value);
      if (code && code.length >= 5) {
        this.onSubmit({ code });
      }
    }
  }

  onScanError(err) {
    console.error(err);
    this.setState({ isScan: false });
  }

  render() {
    const { handleSubmit, valid, submitting, disabled } = this.props;
    const { isScan } = this.state;
    return (
      <Card>
        <CardBody>
          {isScan && (
            <div>
              <Translate value="Scan.scan" tag="h1" className="title" />
              <BarcodeReader
                style={{
                  width: 500,
                }}
                onError={this.onScanError}
                onScan={this.onScan}
                qrCode
                dataMatrix
                code128
                multi={false}
              />
            </div>
          )}
          <Form
            autoComplete="off"
            onSubmit={handleSubmit(this.onSubmit)}
            style={{ width: '100%' }}
          >
            {!isScan && <CodeInput />}
            <div className="w-100 mt-3">
              <div>
                {isScan
                  ? (
                    <Button
                      className="mr-2 app-button"
                      color="primary"
                      size="lg"
                      onClick={this.onModeSwitch}
                      disabled={disabled}
                    >
                      <span>
                        <FA icon={faKeyboard} />
                        &nbsp;
                        <Translate value="Scan.manual" />
                      </span>
                    </Button>
                  )
                  : (
                    <Button
                      className="mr-2 app-button"
                      color="primary"
                      size="lg"
                      type="submit"
                      disabled={!valid || submitting || disabled}
                    >
                      <span>
                        <FA icon={faCheck} />
                        &nbsp;
                        <Translate value="Scan.validate" />
                      </span>
                    </Button>
                  )
                }
                <Button
                  className="app-button"
                  color="secondary"
                  size="lg"
                  onClick={this.onCancel}
                  disabled={submitting || disabled}
                  outline
                >
                  <span>
                    <FA icon={faTimes} />
                    &nbsp;
                    <Translate value="Scan.cancel" />
                  </span>
                </Button>
              </div>
            </div>
          </Form>
        </CardBody>
      </Card>
    );
  }
}

export default compose(
  connect(
    state => ({
      disabled: getStepDisabled(state),
      initialValues: getFormInitialValues(state),
    }),
    {
      completeStep,
      cancelStep,
      addSessionData,
    },
  ),
  reduxForm({
    form: 'data-collect',
  }),
)(Scan);
