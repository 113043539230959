/* global navigator, window */

/**
 * IMPORTS
 */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

/**
 * CORE
 */

class Camera extends Component {
  constructor(props) {
    super(props);

    this.currentCapture = Promise.resolve();
    this.isUnmounting = false;
  }

  componentWillMount() {
    const { mediaDevices } = navigator;
    if (mediaDevices) {
      const options = {
        video: {
          facingMode: 'user',
          width: { ideal: 1280, max: 1920 },
          height: { ideal: 720, max: 1080 },
        },
        audio: false,
      };

      mediaDevices.getUserMedia(options)
        .then((stream) => {
          this.video.srcObject = stream;
          this.video.play();
        })
        .catch((error) => {
          if (this.props.onError) {
            this.props.onError(error);
          } else {
            throw error;
          }
        });
    }
  }

  componentWillUnmount() {
    const { video: { srcObject } } = this;
    this.isUnmounting = true;
    if (srcObject) {
      this.currentCapture
        .then(() => srcObject.getVideoTracks().forEach(track => track.stop()));
    }
  }

  takePhoto(settings = {}) {
    if (this.isUnmounting) {
      return Promise.resolve(null);
    }

    const { video: { srcObject } } = this;
    if (srcObject) {
      const [track] = srcObject.getVideoTracks();
      const imgCapture = new window.ImageCapture(track);
      this.currentCapture = imgCapture.takePhoto(settings)
        .then((blob) => {
          if (this.props.onTakePhoto) {
            this.props.onTakePhoto(blob);
          }
          return blob;
        });
      return this.currentCapture;
    }
    return Promise.reject(new Error('Stream not initialized'));
  }

  grabFrame() {
    if (this.isUnmounting) {
      return Promise.resolve(null);
    }

    const { video: { srcObject } } = this;
    if (srcObject) {
      const [track] = srcObject.getVideoTracks();
      const imgCapture = new window.ImageCapture(track);
      this.currentCapture = imgCapture.grabFrame()
        .then((imgData) => {
          if (this.props.onGrabFrame) {
            this.props.onGrabFrame(imgData);
          }
          return imgData;
        });
      return this.currentCapture;
    }
    return Promise.reject(new Error('Stream not initialized'));
  }

  render() {
    const props = _.omit(this.props, ['onTakePhoto', 'onGrabFrame', 'onError']);
    return (
      <video
        ref={(video) => { this.video = video; }}
        {...props}
      />
    );
  }
}

Camera.propTypes = {
  onTakePhoto: PropTypes.func,
  onGrabFrame: PropTypes.func,
  onError: PropTypes.func,
};

Camera.defaultProps = {
  onTakePhoto: null,
  onGrabFrame: null,
  onError: null,
};

export default Camera;
