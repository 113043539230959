/* global window */

/**
 * IMPORTS
 */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { Translate } from 'react-i18nify';
import { FontAwesomeIcon as FA } from '@fortawesome/react-fontawesome';
import { faTimes, faUndo, faCheck } from '@fortawesome/free-solid-svg-icons';
import {
  Container,
  Card,
  CardBody,
  CardHeader,
  Button,
} from 'reactstrap';

import {
  completeStep,
  cancelStep,
} from '../../actions/nav-actions';
import {
  pickSelfie,
} from '../../actions/session-actions';
import {
  getStepDisabled,
  getSessionLastAttemptStatus,
  getSessionSelfies,
} from '../../selectors';

/**
 * CORE
 */

const loadSelfies = blobs => blobs.map(b => window.URL.createObjectURL(b));
const unloadSelfies = urls => urls.forEach(u => window.URL.revokeObjectURL(u));

class Select extends Component {
  static stepId = 'select';

  static propTypes = {
    disabled: PropTypes.bool,
    isLastAttempt: PropTypes.bool,
    selfies: PropTypes.array.isRequired,
    pickSelfie: PropTypes.func.isRequired,
    completeStep: PropTypes.func.isRequired,
    cancelStep: PropTypes.func.isRequired,
  };

  static defaultProps = {
    isLastAttempt: false,
    disabled: false,
  };

  constructor(props) {
    super(props);

    this.state = {
      urls: loadSelfies(props.selfies),
      selected: 0,
    };

    this.onCancel = this.onCancel.bind(this);
    this.onChange = this.onChange.bind(this);
    this.onValidate = this.onValidate.bind(this);
  }

  componentWillReceiveProps({ selfies }) {
    if (selfies !== this.props.selfies) {
      const { selected } = this.state;
      this.setState({
        urls: loadSelfies(selfies),
        selected: Math.min(selected, selfies.length - 1),
      });
    }
  }

  componentWillUnmount() {
    const { urls } = this.state;
    this.setState(
      { urls: [] },
      () => unloadSelfies(urls),
    );
  }

  onCancel() {
    this.props.cancelStep(Select.stepId);
  }

  onValidate() {
    const { selfies } = this.props;
    const { selected } = this.state;
    this.props.pickSelfie(selfies[selected]);
    this.props.completeStep(Select.stepId);
  }

  onChange(index) {
    this.setState({ selected: index });
  }

  render() {
    const { disabled, isLastAttempt } = this.props;
    const { urls, selected } = this.state;

    if (urls.length === 0) {
      return null;
    }

    return (
      <Container>
        <Card>
          <CardHeader tag="h2">
            <Translate
              value="Select.pick"
              cound={urls.length}
            />
          </CardHeader>
          <CardBody>
            <Carousel
              showArrows={urls.length > 1}
              showIndicators={urls.length > 1}
              showThumbs={false}
              showStatus={false}
              selectedItem={selected}
              onChange={this.onChange}
            >
              {urls.map((url, i) => (
                <div key={i.toString()}>
                  <img src={url} alt="selfie" />
                </div>
              ))}
            </Carousel>
            <div className="mt-2 float-right clearfix">
              <Button
                className="mr-2 app-button"
                color="secondary"
                size="lg"
                onClick={this.onCancel}
                disabled={disabled}
                outline
              >
                <FA icon={isLastAttempt ? faTimes : faUndo} />
                &nbsp;
                <Translate value={isLastAttempt ? 'Select.cancel' : 'Select.retry'} />
              </Button>
              <Button
                className="app-button"
                color="primary"
                size="lg"
                onClick={this.onValidate}
                disabled={disabled}
              >
                <FA icon={faCheck} />
                &nbsp;
                <Translate value="Select.validate" />
              </Button>
            </div>
          </CardBody>
        </Card>
      </Container>
    );
  }
}

export default connect(
  state => ({
    disabled: getStepDisabled(state),
    isLastAttempt: getSessionLastAttemptStatus(state),
    selfies: getSessionSelfies(state),
  }),
  {
    completeStep,
    cancelStep,
    pickSelfie,
  },
)(Select);
