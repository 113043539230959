/**
 * IMPORTS
 */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Translate } from 'react-i18nify';
import { FontAwesomeIcon as FA } from '@fortawesome/react-fontawesome';
import { faTimes, faRedoAlt } from '@fortawesome/free-solid-svg-icons';
import _ from 'lodash';
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from 'reactstrap';

import {
  retryAsync,
  cancelAsync,
} from '../actions/failures-actions';

import '../css/async-error.scss';

/**
 * CORE
 */

class AsyncError extends Component {
  static propTypes = {
    visible: PropTypes.bool,
    requestType: PropTypes.string,
    message: PropTypes.string,
    retryAsync: PropTypes.func.isRequired,
    cancelAsync: PropTypes.func.isRequired,
  };

  static defaultProps = {
    visible: false,
    requestType: undefined,
    message: undefined,
  };

  constructor(props) {
    super(props);

    this.onRetry = () => props.retryAsync();
    this.onCancel = () => props.cancelAsync();
  }

  render() {
    const {
      visible,
      requestType,
      message,
    } = this.props;

    return (
      <Modal
        className="modal-async-error"
        backdropClassName="modal-backdrop-async-error"
        isOpen={visible}
        backdrop="static"
        centered
      >
        <ModalHeader>
          {requestType && <Translate value={`Error.requests.${requestType}`} />}
        </ModalHeader>
        <ModalBody>
          {message || ''}
        </ModalBody>
        <ModalFooter>
          <Button
            className="app-button"
            color="danger"
            onClick={this.onCancel}
            size="lg"
          >
            <FA icon={faTimes} />
            {' '}
            <Translate value="Error.cancel" />
          </Button>
          {' '}
          <Button
            className="app-button"
            color="primary"
            onClick={this.onRetry}
            size="lg"
          >
            <FA icon={faRedoAlt} />
            {' '}
            <Translate value="Error.retry" />
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}

export default connect(
  ({ app: { asyncError } }) => ({
    visible: !!asyncError,
    requestType: _.get(asyncError, 'requestType', undefined),
    message: _.get(asyncError, 'message', undefined),
  }),
  {
    retryAsync,
    cancelAsync,
  },
)(AsyncError);
