/**
 * IMPORTS
 */

import countdownShortSound from '../assets/countdown_short.mp3';
import countdownLongSound from '../assets/countdown_long.mp3';
import triggerSound from '../assets/trigger.mp3';

/**
 * CORE
 */

const initial = {
  sounds: [
    { id: 'trigger', url: triggerSound },
    { id: 'countdown-short', url: countdownShortSound },
    { id: 'countdown-long', url: countdownLongSound },
  ],
  playing: {
    trigger: false,
    'countdown-short': false,
    'countdown-long': false,
  },
};

export default (state = initial, action) => {
  switch (action.type) {
    case 'PLAY_SOUND':
      return {
        ...state,
        playing: {
          ...state.playing,
          [action.sound]: true,
        },
      };

    case 'STOP_SOUND':
      return {
        ...state,
        playing: {
          ...state.playing,
          [action.sound]: false,
        },
      };

    default:
      return state;
  }
};
