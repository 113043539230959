/**
 * IMPORTS
 */

import { I18n } from 'react-i18nify';
import {
  apply,
  takeEvery,
} from 'redux-saga/effects';

/**
 * CORE
 */

function* setLocale({ locale }) {
  yield apply(I18n, I18n.setLocale, [locale]);
}

export default [
  takeEvery('SET_LOCALE', setLocale),
];
