/**
 * IMPORTS
 */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import { Translate, I18n } from 'react-i18nify';
import {
  FormGroup,
  CustomInput,
  FormFeedback,
} from 'reactstrap';

/**
 * CORE
 */

const CheckboxInputComponent = ({
  input,
  meta: { touched, error },
  i18nPath,
  disabled,
  ...attributes
}) => {
  i18nPath = typeof i18nPath === 'string' ? i18nPath : i18nPath.join('.');
  const prefix = i18nPath ? `${i18nPath}.` : '';
  return (
    <FormGroup>
      <CustomInput
        id={input.name}
        type="checkbox"
        invalid={touched && !!error}
        {...input}
        {...attributes}
        label={I18n.t(`${prefix}${input.name}.label`)}
        checked={!!input.value}
        disabled={disabled}
      >
        <FormFeedback>
          <Translate value={`${prefix}${input.name}.${error}`} />
        </FormFeedback>
      </CustomInput>
    </FormGroup>
  );
};

CheckboxInputComponent.propTypes = {
  input: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired,
  disabled: PropTypes.bool,
  i18nPath: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array,
  ]),
};

CheckboxInputComponent.defaultProps = {
  i18nPath: '',
  disabled: false,
};

class CheckboxInput extends Component {
  constructor(props) {
    super(props);
    this.validators = [
      this.validateRequired.bind(this),
    ];
  }

  validateRequired(v) {
    const { required } = this.props;
    return !required || v ? undefined : 'required';
  }

  render() {
    const { required, ...props } = this.props;
    return (
      <Field
        component={CheckboxInputComponent}
        validate={this.validators}
        {...props}
      />
    );
  }
}

CheckboxInput.propTypes = {
  required: PropTypes.bool,
};

CheckboxInput.defaultProps = {
  required: false,
};

export default CheckboxInput;
