/**
 * IMPORTS
 */

import _ from 'lodash';

/**
 * CORE
 */

const initial = {};

function updateProject(state, projectId, data = {}) {
  return {
    ...state,
    [projectId]: {
      initialized: false,
      initializing: false,
      ...state[projectId],
      ...data,
    },
  };
}

export default (state = initial, action) => {
  switch (action.type) {
    case 'INITIALIZE_PROJECT_REQUEST':
      return updateProject(state, action.projectId, {
        id: action.projectId,
        initializing: true,
        initialized: false,
      });

    case 'INITIALIZE_PROJECT_SUCCESS':
      return updateProject(state, action.projectId, {
        id: action.projectId,
        initializing: false,
        initialized: true,
      });

    case 'INITIALIZE_PROJECT_FAILURE':
      return updateProject(state, action.projectId, {
        id: action.projectId,
        initializing: false,
        initialized: false,
      });

    case 'GET_PROJECT_SUCCESS':
      return updateProject(state, action.projectId, _.pick(action.project, [
        'id',
        'name',
        'ttls',
        'enabled',
        'selfie',
        'disabledMessage',
        'metadata',
      ]));

    case 'GET_PROJECT_STATUS_SUCCESS':
      // We replace partially the status bc we mock a response in `onProjectDisabledResponse`
      return updateProject(state, action.projectId, {
        id: action.projectId,
        status: {
          ..._.get(state, `${action.projectId}.status`, {}),
          ...action.status,
        },
      });

    case 'GET_PROJECT_FORM_SUCCESS':
      return updateProject(state, action.projectId, {
        id: action.projectId,
        form: action.form,
      });

    default:
      return state;
  }
};
