/**
 * IMPORTS
 */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import { I18n, Translate } from 'react-i18nify';
import { isEmail } from 'validator';
import {
  FormGroup,
  Input,
  Label,
  FormFeedback,
} from 'reactstrap';

/**
 * CORE
 */

const EmailInputComponent = ({
  input,
  meta: { touched, error },
  i18nPath,
  disabled,
  ...attributes
}) => {
  i18nPath = typeof i18nPath === 'string' ? i18nPath : i18nPath.join('.');
  const prefix = i18nPath ? `${i18nPath}.` : '';
  return (
    <FormGroup disabled={disabled}>
      <Label for={input.name}>
        <Translate value={`${prefix}${input.name}.label`} />
      </Label>
      <Input
        id={input.name}
        invalid={touched && !!error}
        readOnly={disabled}
        type="email"
        placeholder={I18n.t(`${prefix}${input.name}.placeholder`)}
        {...input}
        {...attributes}
      />
      <FormFeedback>
        <Translate value={`${prefix}${input.name}.${error}`} />
      </FormFeedback>
    </FormGroup>
  );
};

EmailInputComponent.propTypes = {
  input: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired,
  disabled: PropTypes.bool,
  i18nPath: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array,
  ]),
};

EmailInputComponent.defaultProps = {
  i18nPath: '',
  disabled: false,
};

class EmailInput extends Component {
  constructor(props) {
    super(props);
    this.validators = [
      this.validateRequired.bind(this),
      v => (v && !isEmail(v) ? 'invalid' : undefined),
    ];
  }

  validateRequired(v) {
    const { required } = this.props;
    return !required || v ? undefined : 'required';
  }

  render() {
    const { required, ...props } = this.props;
    return (
      <Field
        component={EmailInputComponent}
        validate={this.validators}
        {...props}
      />
    );
  }
}

EmailInput.propTypes = {
  required: PropTypes.bool,
};

EmailInput.defaultProps = {
  required: false,
};

export default EmailInput;
