/**
 * IMPORTS
 */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import { I18n, Translate } from 'react-i18nify';
import {
  FormGroup,
  Input,
  Label,
  FormFeedback,
} from 'reactstrap';

/**
 * CORE
 */

const normalizeInt = v => (typeof v === 'string' && v.length > 0 ? Number.parseInt(v, 10) : '');
const normalizeFloat = v => (typeof v === 'string' && v.length > 0 ? Number.parseFloat(v) : '');

const NumberInputComponent = ({
  input,
  meta: { touched, error },
  disabled,
  i18nPath,
  ...attributes
}) => {
  i18nPath = typeof i18nPath === 'string' ? i18nPath : i18nPath.join('.');
  const prefix = i18nPath ? `${i18nPath}.` : '';
  return (
    <FormGroup>
      <Label for={input.name}>
        <Translate value={`${prefix}${input.name}.label`} />
      </Label>
      <Input
        id={input.name}
        invalid={touched && !!error}
        readOnly={disabled}
        type="number"
        placeholder={I18n.t(`${prefix}${input.name}.placeholder`)}
        {...input}
        {...attributes}
      />
      <FormFeedback>
        <Translate value={`${prefix}${input.name}.${error}`} />
      </FormFeedback>
    </FormGroup>
  );
};

NumberInputComponent.propTypes = {
  input: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired,
  disabled: PropTypes.bool,
  i18nPath: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array,
  ]),
};

NumberInputComponent.defaultProps = {
  i18nPath: '',
  disabled: true,
};

class NumberInput extends Component {
  constructor(props) {
    super(props);
    this.validators = [
      this.validateRequired.bind(this),
      this.validateInteger.bind(this),
      this.validateMinValue.bind(this),
      this.validateMaxValue.bind(this),
    ];
  }

  validateRequired(v) {
    const { required } = this.props;
    return !required || v ? undefined : 'required';
  }

  validateInteger(v) {
    const { integer } = this.props;
    return !integer || !v || (typeof v === 'number' && Number.isInteger(v)) ? undefined : 'invalid';
  }

  validateMinValue(v) {
    const { min } = this.props;
    return (Number.isNaN(min) || !v || (typeof v === 'number' && v >= min) ? undefined : 'invalid');
  }

  validateMaxValue(v) {
    const { max } = this.props;
    return (Number.isNaN(max) || !v || (typeof v === 'number' && v <= max) ? undefined : 'invalid');
  }

  render() {
    const { required, min, max, integer, ...props } = this.props;
    return (
      <Field
        component={NumberInputComponent}
        validate={this.validators}
        normalize={integer ? normalizeInt : normalizeFloat}
        {...props}
      />
    );
  }
}

NumberInput.propTypes = {
  required: PropTypes.bool,
  integer: PropTypes.bool,
  min: PropTypes.number,
  max: PropTypes.number,
};

NumberInput.defaultProps = {
  required: false,
  integer: false,
  min: NaN,
  max: NaN,
};

export default NumberInput;
