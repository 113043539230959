import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';

import app from './app';
import projects from './projects';
import session from './session';
import api from './api';
import sound from './sound';
import queue from './queue';

export default combineReducers({
  app,
  projects,
  session,
  api,
  form: formReducer,
  sound,
  queue,
});
