/**
 * IMPORTS
 */

const { v4: uuid } = require('uuid');

/**
 * SYNC
 */

export const pickProject = projectId => (
  {
    type: 'PICK_PROJECT',
    projectId,
  }
);

export const unpickProject = () => (
  {
    type: 'UNPICK_PROJECT',
  }
);

/**
 * ASYNC
 */

export const initializeProject = projectId => (
  {
    type: 'INITIALIZE_PROJECT_REQUEST',
    projectId,
    requestId: uuid(),
  }
);

export const getProjectId = client => (
  {
    type: 'GET_PROJECT_ID_REQUEST',
    client,
    requestId: uuid(),
  }
);

export const getProject = projectId => (
  {
    type: 'GET_PROJECT_REQUEST',
    projectId,
    requestId: uuid(),
  }
);

export const getProjectStatus = projectId => (
  {
    type: 'GET_PROJECT_STATUS_REQUEST',
    projectId,
    requestId: uuid(),
  }
);

export const getProjectForm = projectId => (
  {
    type: 'GET_PROJECT_FORM_REQUEST',
    projectId,
    requestId: uuid(),
  }
);
