/* global window */

/**
 * IMPORTS
 */

import _ from 'lodash';

/**
 * CORE
 */

const { localStorage } = window;

export function loadState() {
  try {
    const serializedState = localStorage.getItem('redux-state');
    if (serializedState === null) {
      return undefined;
    }

    const state = JSON.parse(serializedState);
    // prevent "running" queued item to block further consumption
    state.queue = _.get(state, 'queue', [])
      .map(item => ({ ...item, running: false }));
    return state;
  } catch (err) {
    return undefined;
  }
}

export function saveState(state) {
  try {
    // NOTE: for now we only save the queue
    const serializedState = JSON.stringify(_.pick(state, 'queue'));
    localStorage.setItem('redux-state', serializedState);
  } catch {
    // ignore write errors
  }
}
