/**
 * IMPORTS
 */

import Panorame from 'panorame-client';
import {
  takeEvery,
  apply,
  put,
  cancelled,
  race,
  delay,
  select,
  take,
  call,
} from 'redux-saga/effects';
import uuid from 'uuid/v4';
import axios from 'axios';

import { getProjectId as getProjectIdRequest } from '../actions/project-actions';
import { getClientSelector } from '../selectors';
import getApiUri from '../utils/getApiUri';

/**
 * CONSTANTS
 */

const TIMEOUT = 15 * 1000;
const PANORAME_URI = getApiUri();

/**
 * CORE
 */

function* callApi(client, fn, ...args) {
  const apiRequestId = uuid();
  const context = { apiRequestId, client, fn, args };
  yield put({ type: 'API_CALL_REQUEST', apiRequestId });
  try {
    const { result, timeout } = yield race({
      result: apply(client, fn, args),
      timeout: delay(TIMEOUT, true),
    });

    if (timeout) {
      throw new Error('timeout');
    }

    yield put({ type: 'API_CALL_SUCCESS', result, ...context });

    return result;
  } catch (error) {
    yield put({ type: 'API_CALL_FAILURE', error, ...context });
    throw error;
  } finally {
    if (yield cancelled()) {
      yield put({ type: 'API_CALL_CANCELLED', ...context });
    }
  }
}

function* callProjectApi(fnName, projectId, ...args) {
  const clientSelector = yield select(getClientSelector);
  const { client } = yield call(clientSelector, projectId);
  if (!client) {
    throw new Error(`No client found for project "${projectId}"`);
  }
  const res = yield callApi(client, client[fnName], ...[projectId, ...args]);
  return res;
}

function* getProjectId({ client, requestId }) {
  try {
    const { project } = yield callApi(client, client.getInfo);
    yield put({ type: 'GET_PROJECT_ID_SUCCESS', client, projectId: project, requestId });
  } catch (error) {
    yield put({ type: 'GET_PROJECT_ID_FAILURE', client, error, requestId });
  }
}

function* getProject({ projectId, requestId }) {
  try {
    const project = yield callProjectApi('getProject', projectId);
    yield put({ type: 'GET_PROJECT_SUCCESS', projectId, project, requestId });
  } catch (error) {
    yield put({ type: 'GET_PROJECT_FAILURE', projectId, error, requestId });
  }
}

function* getProjectStatus({ projectId, requestId }) {
  try {
    // getProjectStatus uses `true` to get the detailed status version (ie. obj instead of str)
    const status = yield callProjectApi('getProjectStatus', projectId, true);
    yield put({ type: 'GET_PROJECT_STATUS_SUCCESS', projectId, status, requestId });
  } catch (error) {
    yield put({ type: 'GET_PROJECT_STATUS_FAILURE', projectId, error, requestId });
  }
}

function* getProjectForm({ projectId, requestId }) {
  try {
    const form = yield callProjectApi('getProjectForm', projectId);
    yield put({ type: 'GET_PROJECT_FORM_SUCCESS', projectId, form, requestId });
  } catch (error) {
    yield put({ type: 'GET_PROJECT_FORM_FAILURE', projectId, error, requestId });
  }
}

function* createSession({ projectId, requestId }) {
  try {
    const session = yield callProjectApi('createSession', projectId);
    yield put({ type: 'CREATE_SESSION_SUCCESS', projectId, session, requestId });
  } catch (error) {
    yield put({ type: 'CREATE_SESSION_FAILURE', projectId, error, requestId });
  }
}

function* cancelSession({ projectId, sessionId, requestId }) {
  try {
    yield callProjectApi('cancelSession', projectId, sessionId);
    yield put({ type: 'CANCEL_SESSION_SUCCESS', projectId, sessionId, requestId });
  } catch (error) {
    yield put({ type: 'CANCEL_SESSION_FAILURE', projectId, sessionId, error, requestId });
  }
}

function* triggerSession({ projectId, sessionId, requestId }) {
  try {
    yield callProjectApi('triggerSession', projectId, sessionId);
    yield put({ type: 'TRIGGER_SESSION_SUCCESS', projectId, sessionId, requestId });
  } catch (error) {
    yield put({ type: 'TRIGGER_SESSION_FAILURE', projectId, sessionId, error, requestId });
  }
}

function* validateSession({ projectId, sessionId, requestId }) {
  try {
    yield callProjectApi('validateSession', projectId, sessionId);
    yield put({ type: 'VALIDATE_SESSION_SUCCESS', projectId, sessionId, requestId });
  } catch (error) {
    yield put({ type: 'VALIDATE_SESSION_FAILURE', projectId, sessionId, error, requestId });
  }
}

function* retrySession({ projectId, sessionId, requestId }) {
  try {
    const session = yield callProjectApi('retrySession', projectId, sessionId);
    yield put({ type: 'RETRY_SESSION_SUCCESS', projectId, sessionId, session, requestId });
  } catch (error) {
    yield put({ type: 'RETRY_SESSION_FAILURE', projectId, sessionId, error, requestId });
  }
}

function* sendSessionData({ projectId, sessionId, data = {}, requestId }) {
  try {
    yield callProjectApi('sendSessionData', projectId, sessionId, data);
    yield put({ type: 'SEND_SESSION_DATA_SUCCESS', projectId, sessionId, data, requestId });
  } catch (error) {
    yield put({ type: 'SEND_SESSION_DATA_FAILURE', projectId, sessionId, data, error, requestId });
  }
}

function* getSessionLeaf({ projectId, sessionId, requestId }) {
  try {
    const leaf = yield callProjectApi('getSessionLeaf', projectId, sessionId);
    yield put({ type: 'GET_SESSION_LEAF_SUCCESS', projectId, sessionId, leaf, requestId });
  } catch (error) {
    yield put({ type: 'GET_SESSION_LEAF_FAILURE', projectId, sessionId, error, requestId });
  }
}

function* setSessionLeaf({ projectId, sessionId, leaf, requestId }) {
  try {
    yield callProjectApi('setSessionLeaf', projectId, sessionId, leaf);
    yield put({ type: 'SET_SESSION_LEAF_SUCCESS', projectId, sessionId, leaf, requestId });
  } catch (error) {
    yield put({ type: 'SET_SESSION_LEAF_FAILURE', projectId, sessionId, leaf, error, requestId });
  }
}

function* setSessionRoot({ projectId, sessionId, root, requestId }) {
  try {
    yield callProjectApi('setSessionRoot', projectId, sessionId, root);
    yield put({ type: 'SET_SESSION_ROOT_SUCCESS', projectId, sessionId, root, requestId });
  } catch (error) {
    yield put({ type: 'SET_SESSION_ROOT_FAILURE', projectId, sessionId, root, error, requestId });
  }
}

function* getUploadStatus({ projectId, sessionId, requestId }) {
  try {
    const uploads = yield callProjectApi('getUploadStatus', projectId, sessionId);
    yield put({ type: 'GET_UPLOAD_STATUS_SUCCESS', projectId, sessionId, uploads, requestId });
  } catch (error) {
    yield put({ type: 'GET_UPLOAD_STATUS_FAILURE', projectId, sessionId, error, requestId });
  }
}

function* uploadSelfie({ projectId, sessionId, selfie, requestId }) {
  try {
    yield callProjectApi('uploadSelfie', projectId, sessionId, selfie);
    yield put({ type: 'UPLOAD_SELFIE_SUCCESS', projectId, sessionId, requestId });
  } catch (error) {
    yield put({ type: 'UPLOAD_SELFIE_FAILURE', projectId, sessionId, error, requestId });
  }
}

function onlineRequest(timeout = 5000) {
  return axios.head(
    `${PANORAME_URI}/v2/online`,
    { timeout },
  );
}

function* isOnline({ timeout, requestId }) {
  try {
    yield call(onlineRequest, timeout);
    yield put({ type: 'ONLINE_SUCCESS', requestId });
  } catch (error) {
    yield put({ type: 'ONLINE_FAILURE', error, requestId });
  }
}

function* initializeClient({ appId, requestId }) {
  const client = new Panorame(appId, undefined, { uri: PANORAME_URI });
  yield put(getProjectIdRequest(client));
  const action = yield take(({ type, client: c }) => (
    ['GET_PROJECT_ID_SUCCESS', 'GET_PROJECT_ID_FAILURE'].includes(type)
    && client === c
  ));
  if (action.type === 'GET_PROJECT_ID_SUCCESS') {
    const { projectId } = action;
    yield put({ type: 'INITIALIZE_CLIENT_SUCCESS', appId, client, projectId, requestId });
  } else {
    const { error } = action;
    yield put({ type: 'INITIALIZE_CLIENT_FAILURE', appId, error, requestId });
  }
}

export default [
  takeEvery('INITIALIZE_CLIENT_REQUEST', initializeClient),
  takeEvery('GET_PROJECT_ID_REQUEST', getProjectId),
  takeEvery('GET_PROJECT_REQUEST', getProject),
  takeEvery('GET_PROJECT_STATUS_REQUEST', getProjectStatus),
  takeEvery('GET_PROJECT_FORM_REQUEST', getProjectForm),
  takeEvery('CREATE_SESSION_REQUEST', createSession),
  takeEvery('CANCEL_SESSION_REQUEST', cancelSession),
  takeEvery('TRIGGER_SESSION_REQUEST', triggerSession),
  takeEvery('VALIDATE_SESSION_REQUEST', validateSession),
  takeEvery('RETRY_SESSION_REQUEST', retrySession),
  takeEvery('SEND_SESSION_DATA_REQUEST', sendSessionData),
  takeEvery('SET_SESSION_LEAF_REQUEST', setSessionLeaf),
  takeEvery('SET_SESSION_ROOT_REQUEST', setSessionRoot),
  takeEvery('GET_UPLOAD_STATUS_REQUEST', getUploadStatus),
  takeEvery('GET_SESSION_LEAF_REQUEST', getSessionLeaf),
  takeEvery('UPLOAD_SELFIE_REQUEST', uploadSelfie),
  takeEvery('ONLINE_REQUEST', isOnline),
];
