/**
 * IMPORT
 */

import {
  take,
  takeEvery,
  put,
} from 'redux-saga/effects';

import {
  showAsyncError,
  hideAsyncError,
} from '../actions/failures-actions';

/**
 * CORE
 */

function* handleAsyncRequest(action, typePrefix) {
  const { error } = yield take(({ type, requestId }) => (
    type === `${typePrefix}_FAILURE` && requestId === action.requestId
  ));

  if (error) {
    yield put(showAsyncError(`${typePrefix}_REQUEST`, error));

    const { type } = yield take(['RETRY_ASYNC', 'CANCEL_ASYNC']);

    yield put(hideAsyncError());

    if (type === 'RETRY_ASYNC') {
      yield put(action);
    } else {
      // TODO: send error to server
      yield put({ type: `${typePrefix}_CANCELLED`, requestId: action.requestId });
    }
  }
}

export default [
  'INITIALIZE_PROJECT',
  'CREATE_SESSION',
  'TRIGGER_SESSION',
  'RETRY_SESSION',
].map(prefix => (
  takeEvery(`${prefix}_REQUEST`, function* onAsyncRequest(requestAction) {
    yield handleAsyncRequest(requestAction, prefix);
  })
));
