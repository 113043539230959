/**
 * CORE
 */

export const getLocation = () => (
  {
    type: 'GET_LOCATION_REQUEST',
  }
);

export const getWeather = location => (
  {
    type: 'GET_WEATHER_REQUEST',
    location,
  }
);
