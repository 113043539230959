/**
 * IMPORTS
 */

import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Alert, Badge } from 'reactstrap';

import { getCurrentProjectStatus } from '../../selectors';

/**
 * CORE
 */

const transformStatus = ({
  isOK = true,
  projectIsEnabled = true,
  projectHasContext = true,
  deviceIsOnline = true,
  deviceHasCameras = true,
  disabledMessage = 'Disabled',
}) => {
  if (isOK) {
    return {
      label: 'Online',
      level: 'success',
      message: 'Up and running',
    };
  }
  if (!projectIsEnabled) {
    return {
      label: 'Disabled',
      level: 'warning',
      message: disabledMessage,
    };
  }
  if (!projectHasContext) {
    return {
      label: 'Error',
      level: 'danger',
      message: 'Project has no context',
    };
  }
  if (!deviceIsOnline) {
    return {
      label: 'Offline',
      level: 'danger',
      message: 'Device is unreachable',
    };
  }
  if (!deviceHasCameras) {
    return {
      label: 'Error',
      level: 'danger',
      message: 'Invalid camera count',
    };
  }
  return {
    label: 'Error',
    level: 'danger',
    message: 'Unknown issue',
  };
};

const NotReady = ({ projectStatus }) => {
  const { label, level, message } = transformStatus(projectStatus);
  return (
    <Fragment>
      <h3 className="mb-3">
        <Badge
          color={level}
          pill
        >
          {label}
        </Badge>
      </h3>
      {message && (
        <Alert color={level}>
          {message}
        </Alert>
      )}
    </Fragment>
  );
};

NotReady.propTypes = {
  projectStatus: PropTypes.object.isRequired,
};

export default connect(
  state => ({
    projectStatus: getCurrentProjectStatus(state),
  }),
)(NotReady);
