/**
 * IMPORTS
 */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { CSSTransition } from 'react-transition-group';
import classNames from 'classnames';

import '../../css/transitions.scss';

/**
 * CORE
 */

class Step extends Component {
  static propTypes = {
    children: PropTypes.node.isRequired,
    id: PropTypes.string,
    horizontal: PropTypes.bool,
    keyboard: PropTypes.bool,
  };

  static defaultProps = {
    id: undefined,
    horizontal: false,
    keyboard: false,
  };

  constructor(props) {
    super(props);

    this.state = { entered: false };

    this.onEntered = () => this.setState({ entered: true });
    this.onExit = () => this.setState({ entered: false });
  }

  render() {
    const {
      id,
      horizontal,
      children,
      keyboard,
      ...attributes
    } = this.props;

    const classes = [
      'd-flex',
      horizontal ? 'flex-row' : 'flex-column',
      'flex-nowrap',
      'justify-content-center',
      'align-items-center',
    ];

    return (
      <CSSTransition
        timeout={400}
        classNames="step-transition"
        onEntered={this.onEntered}
        onExit={this.onExit}
        {...attributes}
      >
        <div
          id={id}
          className={classNames(
            'app-step',
            classes,
            { 'with-keyboard': keyboard },
          )}
        >
          {React.cloneElement(children, { entered: this.state.entered })}
        </div>
      </CSSTransition>
    );
  }
}

export default Step;
