/**
 * IMPORTS
 */

import {
  call,
  put,
  select,
  take,
  takeEvery,
} from 'redux-saga/effects';
import {
  dequeueAction,
} from '../actions/queue-actions';
import {
  getQueuedItemSelector,
  getQueuedItemsMaxAttempts,
} from '../selectors';

/**
 * CORE
 */

function* onAttemptAction({ itemId }) {
  const itemSelector = yield select(getQueuedItemSelector);
  const item = yield call(itemSelector, itemId);

  if (!item) return;

  const { action, typePrefix } = item;
  yield put(action);
  const { type: result } = yield take(({ requestId }) => requestId === action.requestId);
  if (result === `${typePrefix}_SUCCESS`) {
    yield put({ type: 'ATTEMPT_ACTION_SUCCESS', itemId });
  } else {
    yield put({ type: 'ATTEMPT_ACTION_FAILURE', itemId });
  }
}

function* onAttemptActionSuccess({ itemId }) {
  // Success -> Remove from queue
  yield put(dequeueAction(itemId));
}

function* onAttemptActionFailure({ itemId }) {
  // remove item from queue if we reached max attempts
  const maxAttempts = yield select(getQueuedItemsMaxAttempts);
  const itemSelector = yield select(getQueuedItemSelector);
  const item = yield call(itemSelector, itemId);
  if (item.attempts >= maxAttempts) {
    // TODO: log
    yield put(dequeueAction(itemId));
  }
}

export default [
  takeEvery('ATTEMPT_ACTION', onAttemptAction),
  takeEvery('ATTEMPT_ACTION_SUCCESS', onAttemptActionSuccess),
  takeEvery('ATTEMPT_ACTION_FAILURE', onAttemptActionFailure),
];
