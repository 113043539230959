/**
 * IMPORTS
 */

import {
  takeEvery,
  take,
  put,
} from 'redux-saga/effects';

import {
  getProject,
  getProjectStatus,
  getProjectForm,
  initializeProject,
} from '../actions/project-actions';

/**
 * CORE
 */

function* onInitializeProject({ projectId }) {
  try {
    yield put(getProject(projectId));

    const getProjectAction = yield take([
      'GET_PROJECT_SUCCESS',
      'GET_PROJECT_FAILURE',
    ]);

    if (getProjectAction.type === 'GET_PROJECT_FAILURE') {
      throw getProjectAction.error;
    }

    yield put(getProjectStatus(projectId));

    const getProjectStatusAction = yield take([
      'GET_PROJECT_STATUS_SUCCESS',
      'GET_PROJECT_STATUS_FAILURE',
    ]);

    if (getProjectStatusAction.type === 'GET_PROJECT_STATUS_FAILURE') {
      throw getProjectStatusAction.error;
    }

    yield put(getProjectForm(projectId));

    const getProjectFormAction = yield take([
      'GET_PROJECT_FORM_SUCCESS',
      'GET_PROJECT_FORM_FAILURE',
    ]);

    if (getProjectFormAction.type === 'GET_PROJECT_FORM_FAILURE') {
      throw getProjectFormAction.error;
    }

    yield put({
      type: 'INITIALIZE_PROJECT_SUCCESS',
      projectId,
      project: {
        ...getProjectAction.project,
        status: getProjectStatusAction.status,
        form: getProjectFormAction.form,
      },
    });
  } catch (error) {
    yield put({ type: 'INITIALIZE_PROJECT_FAILURE', projectId, error });
  }
}

function* onProjectDisabledResponse({ projectId }) {
  // we fake a getProjectStatus success
  yield put({
    type: 'GET_PROJECT_STATUS_SUCCESS',
    projectId,
    status: {
      isOK: false,
      projectIsEnabled: false,
      disabledMessage: 'Project is disabled',
    },
  });
}

function* onInitializeClientSuccess({ projectId }) {
  yield put(initializeProject(projectId));
}

export default [
  takeEvery('INITIALIZE_PROJECT_REQUEST', onInitializeProject),
  takeEvery(({ type, projectId, error }) => (
    type.endsWith('_FAILURE') // is a failure
    && !!projectId // has a project id attached
    && !!error // has an error object attached with following props:
    && error.name === 'PanorameError'
    && error.code === 503
    && error.message === 'Project is disabled'
  ), onProjectDisabledResponse),
  takeEvery('INITIALIZE_CLIENT_SUCCESS', onInitializeClientSuccess),
];
