/**
 * IMPORTS
 */

const _ = require('lodash');

/**
 * GLOBALS
 */

const MAX_DELAY = 30 * 60 * 1000; // 30 minutes
const EXP_DURATION = 5000; // 5 seconds

/**
 * UTILS
 */

// NOTE: will delay by EXP_DURATION, 2*EXP_DURATION, 4*EXP_DURATION, ...
const getExpDelay = attempts => (
  Math.max(EXP_DURATION, Math.min(MAX_DELAY, (2 ** (attempts - 1)) * EXP_DURATION))
);

/**
 * CORE
 */

const initial = [];

export default (state = initial, action) => {
  switch (action.type) {
    case 'ENQUEUE_ACTION':
      return [
        ...state,
        {
          ..._.omit(action, 'type'),
          running: false,
          attempts: 0,
          nextAttempt: -1, // ASAP
        },
      ];

    case 'ATTEMPT_ACTION':
      return state.map(item => (
        item.itemId !== action.itemId
          ? item
          : {
            ...item,
            running: true,
            attempts: item.attempts + 1,
          }
      ));

    case 'ATTEMPT_ACTION_SUCCESS':
    case 'DEQUEUE_ACTION':
      return state.filter(({ itemId }) => itemId !== action.itemId);

    case 'ATTEMPT_ACTION_FAILURE':
      return state.map(item => (
        item.itemId !== action.itemId
          ? item
          : {
            ...item,
            running: false,
            nextAttempt: Date.now() + getExpDelay(item.attempts),
          }
      ));

    default:
      return state;
  }
};
