/**
 * IMPORTS
 */

import React from 'react';
import PropTypes from 'prop-types';
import { Card, CardBody } from 'reactstrap';

/**
 * CORE
 */

const Message = ({ text }) => (
  <Card>
    <CardBody>
      {text.split('\n').map((t, i) => <p key={String(i)}>{t}</p>)}
    </CardBody>
  </Card>
);

Message.propTypes = {
  text: PropTypes.string.isRequired,
};

export default Message;
