/* global window */

/**
 * IMPORTS
 */

import { decode } from 'querystring';

/**
 * GLOBALS
 */

const PANORAME_URI_TEST = 'https://testing.api.panora.me';

const {
  REACT_APP_API_URL: PANORAME_URI_PROD,
} = process.env;

/**
 * CORE
 */

export default () => {
  const qs = decode(window.location.search.replace(/^\?/, ''));
  return qs.test ? PANORAME_URI_TEST : PANORAME_URI_PROD;
};
