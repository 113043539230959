/**
 * IMPORTS
 */

const { v4: uuid } = require('uuid');

/**
 * SYNC
 */

export const clearSession = () => (
  {
    type: 'CLEAR_SESSION',
  }
);

export const setSessionFrame = (scale, center) => (
  {
    type: 'SET_SESSION_FRAME',
    scale,
    center,
  }
);

export const resetSessionFrame = () => (
  {
    type: 'RESET_SESSION_FRAME',
  }
);

export const addSelfie = blob => (
  {
    type: 'ADD_SELFIE_BLOB',
    blob,
  }
);

export const pickSelfie = blob => (
  {
    type: 'PICK_SELFIE_BLOB',
    blob,
  }
);

export const addSessionData = data => (
  {
    type: 'ADD_SESSION_DATA',
    data,
  }
);

/**
 * ASYNC
 */

export const createSession = projectId => (
  {
    type: 'CREATE_SESSION_REQUEST',
    projectId,
    requestId: uuid(),
  }
);

export const cancelSession = (projectId, sessionId) => (
  {
    type: 'CANCEL_SESSION_REQUEST',
    projectId,
    sessionId,
    requestId: uuid(),
  }
);

export const triggerSession = (projectId, sessionId) => (
  {
    type: 'TRIGGER_SESSION_REQUEST',
    projectId,
    sessionId,
    requestId: uuid(),
  }
);

export const validateSession = (projectId, sessionId) => (
  {
    type: 'VALIDATE_SESSION_REQUEST',
    projectId,
    sessionId,
    requestId: uuid(),
  }
);

export const retrySession = (projectId, sessionId) => (
  {
    type: 'RETRY_SESSION_REQUEST',
    projectId,
    sessionId,
    requestId: uuid(),
  }
);

export const sendSessionData = (projectId, sessionId, data) => (
  {
    type: 'SEND_SESSION_DATA_REQUEST',
    projectId,
    sessionId,
    data,
    requestId: uuid(),
  }
);

export const getSessionLeaf = (projectId, sessionId) => (
  {
    type: 'GET_SESSION_LEAF_REQUEST',
    projectId,
    sessionId,
    requestId: uuid(),
  }
);

export const setSessionLeaf = (projectId, sessionId, leaf) => (
  {
    type: 'SET_SESSION_LEAF_REQUEST',
    projectId,
    sessionId,
    leaf,
    requestId: uuid(),
  }
);

export const setSessionRoot = (projectId, sessionId, root) => (
  {
    type: 'SET_SESSION_ROOT_REQUEST',
    projectId,
    sessionId,
    root,
    requestId: uuid(),
  }
);

export const getUploadStatus = (projectId, sessionId) => (
  {
    type: 'GET_UPLOAD_STATUS_REQUEST',
    projectId,
    sessionId,
    requestId: uuid(),
  }
);

export const uploadSelfie = (projectId, sessionId, selfie) => (
  {
    type: 'UPLOAD_SELFIE_REQUEST',
    projectId,
    sessionId,
    selfie,
    requestId: uuid(),
  }
);
