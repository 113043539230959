/**
 * IMPORTS
 */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Select, { Creatable } from 'react-select';
import { I18n, Translate } from 'react-i18nify';
import { Field } from 'redux-form';
import _ from 'lodash';
import {
  FormGroup,
  Label,
} from 'reactstrap';

import '../../css/select-input.scss';

/**
 * CORE
 */

// TODO: other doesn't work because `options` is mutated in current impl.
const SelectInputComponent = ({
  input,
  meta: { touched, error },
  options: rawOptions,
  required,
  multiple,
  other,
  i18nPath,
  disabled,
  ...attributes
}) => {
  i18nPath = typeof i18nPath === 'string' ? i18nPath : i18nPath.join('.');
  const prefix = i18nPath ? `${i18nPath}.` : '';
  const SelectComponent = other ? Creatable : Select;
  const others = [].concat(input.value).filter(v => v && !rawOptions.includes(v));
  const options = [
    ...rawOptions.map(value => ({ value })),
    ...others.map(value => ({ value })),
  ];
  return (
    <FormGroup>
      <Label for={input.name}>
        <Translate value={`${prefix}${input.name}.label`} />
      </Label>
      <SelectComponent
        options={options}
        blurInputOnSelect={false}
        getOptionLabel={({ value }) => I18n.t(`${prefix}${input.name}.options.${value}`)}
        placeholder={I18n.t(`${prefix}${input.name}.placeholder`)}
        isSearchable={other || options.length > 10}
        isClearable={!required}
        isMulti={multiple}
        isDisabled={disabled}
        classNamePrefix="react-select"
        {...input}
        value={multiple
          ? options.filter(({ value }) => input.value.includes(value))
          : options.find(({ value }) => value === input.value)}
        onBlur={() => input.onBlur && input.onBlur(input.value)} // prevent reset on blur
        onChange={(selected) => {
          if (!input.onChange) return;
          if (multiple) {
            input.onChange((selected || []).map(({ value }) => value));
          } else {
            input.onChange(_.get(selected, 'value', ''));
          }
        }}
        {...attributes}
        styles={{
          option: provided => ({
            ...provided,
            color: '#495057',
          }),
          singleValue: provided => ({
            ...provided,
            color: '#495057',
          }),
        }}
      />
      {touched && error && (
        <div className="invalid-feedback" style={{ display: 'block' }}>
          <Translate value={`${prefix}${input.name}.${error}`} />
        </div>
      )}
    </FormGroup>
  );
};

SelectInputComponent.propTypes = {
  input: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired,
  disabled: PropTypes.bool,
  i18nPath: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array,
  ]),
  options: PropTypes.arrayOf(PropTypes.string).isRequired,
  required: PropTypes.bool,
  multiple: PropTypes.bool,
  other: PropTypes.bool,
};

SelectInputComponent.defaultProps = {
  i18nPath: '',
  disabled: false,
  required: false,
  multiple: false,
  other: false,
};

class SelectInput extends Component {
  constructor(props) {
    super(props);
    this.validators = [
      this.validateRequired.bind(this),
    ];
  }

  validateRequired(v) {
    const { required } = this.props;
    return !required || v ? undefined : 'required';
  }

  render() {
    const { required, ...props } = this.props;
    return (
      <Field
        component={SelectInputComponent}
        validate={this.validators}
        required={required}
        {...props}
      />
    );
  }
}

SelectInput.propTypes = {
  options: PropTypes.arrayOf(PropTypes.string).isRequired,
  required: PropTypes.bool,
};

SelectInput.defaultProps = {
  required: false,
};

export default SelectInput;
