/**
 * IMPORTS
 */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { reduxForm } from 'redux-form';
import { FontAwesomeIcon as FA } from '@fortawesome/react-fontawesome';
import { faTimes, faCheck } from '@fortawesome/free-solid-svg-icons';
import { Translate } from 'react-i18nify';
import _ from 'lodash';
import {
  Container,
  Form as ReactForm,
  Card,
  CardBody,
  CardHeader,
  Button,
} from 'reactstrap';

import {
  completeStep,
  cancelStep,
} from '../../actions/nav-actions';
import {
  getProjectForm,
} from '../../actions/project-actions';
import {
  addSessionData,
} from '../../actions/session-actions';
import {
  getCurrentProjectId,
  getStepDisabled,
  getCurrentFormFields,
  getFormInitialValues,
} from '../../selectors';

import CheckboxInput from '../inputs/CheckboxInput';
import TextInput from '../inputs/TextInput';
import EmailInput from '../inputs/EmailInput';
import MultipleEmailInput from '../inputs/MultipleEmailInput';
import SelectInput from '../inputs/SelectInput';
import NumberInput from '../inputs/NumberInput';

/**
 * Form Input Component
 */

const FormInput = ({ name, type, ...rest }) => {
  const props = {
    name,
    key: name,
    i18nPath: 'Form',
    ..._.omit(rest, 'anonymizable'),
  };

  if (name === 'locale') {
    return null;
  }

  switch (type) {
    case 'text':
      return <TextInput {...props} />;

    case 'boolean':
      return <CheckboxInput {...props} />;

    case 'number':
      return <NumberInput {...props} />;

    case 'choice':
      return <SelectInput {...props} />;

    case 'email':
      return rest.multiple
        ? <MultipleEmailInput {...props} />
        : <EmailInput {...props} />;

    default:
      return null;
  }
};

FormInput.propTypes = {
  name: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
};

/**
 * CORE
 */

class Form extends Component {
  static stepId = 'form';

  static propTypes = {
    disabled: PropTypes.bool,
    projectId: PropTypes.string.isRequired,
    fields: PropTypes.arrayOf(PropTypes.object).isRequired,
    handleSubmit: PropTypes.func.isRequired,
    addSessionData: PropTypes.func.isRequired,
    getProjectForm: PropTypes.func.isRequired,
    completeStep: PropTypes.func.isRequired,
    cancelStep: PropTypes.func.isRequired,
  };

  static defaultProps = {
    disabled: false,
  };

  constructor(props) {
    super(props);

    this.onSubmit = this.onSubmit.bind(this);
    this.onCancel = this.onCancel.bind(this);
  }

  componentWillMount() {
    const { projectId } = this.props;
    this.props.getProjectForm(projectId);
  }

  onSubmit(data) {
    this.props.addSessionData(data);
    this.props.completeStep(Form.stepId);
  }

  onCancel(event) {
    if (event) {
      event.preventDefault();
    }

    this.props.cancelStep(Form.stepId);
  }

  render() {
    const { fields, handleSubmit, disabled } = this.props;

    return (
      <Container style={{ maxWidth: 720 }}>
        <Card>
          <CardHeader tag="h2">
            <Translate value="Form.title" />
          </CardHeader>
          <CardBody>
            <ReactForm
              onSubmit={handleSubmit(this.onSubmit)}
              noValidate
            >
              {fields.map(({ name, ...rest }) => (
                <FormInput
                  key={name}
                  name={name}
                  disabled={disabled}
                  {...rest}
                />
              ))}

              <Button
                className="app-button mr-2"
                color="secondary"
                outline
                onClick={this.onCancel}
                disabled={disabled}
              >
                <FA icon={faTimes} />
                &nbsp;
                <Translate value="Form.cancel" />
              </Button>
              <Button
                className="app-button"
                color="primary"
                disabled={disabled}
              >
                <FA icon={faCheck} />
                &nbsp;
                <Translate value="Form.submit" />
              </Button>
            </ReactForm>
          </CardBody>
        </Card>
      </Container>
    );
  }
}

export default compose(
  connect(
    state => ({
      disabled: getStepDisabled(state),
      projectId: getCurrentProjectId(state),
      fields: getCurrentFormFields(state),
      initialValues: getFormInitialValues(state),
    }),
    {
      completeStep,
      cancelStep,
      getProjectForm,
      addSessionData,
    },
  ),
  reduxForm({
    form: 'data-collect',
  }),
)(Form);
