/**
 * IMPORTS
 */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import { Translate } from 'react-i18nify';
import { FormGroup } from 'reactstrap';
import classnames from 'classnames';
import { FontAwesomeIcon as FA } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';

import '../../css/switch-input.scss';

/**
 * CORE
 */

const SwitchInputComponent = ({
  input,
  meta: { touched, error },
  i18nPath,
  disabled,
  ...attributes
}) => {
  i18nPath = typeof i18nPath === 'string' ? i18nPath : i18nPath.join('.');
  const prefix = i18nPath ? `${i18nPath}.` : '';
  return (
    <FormGroup disabled={disabled}>
      <div className="checkbox">
        <label htmlFor={input.name}>
          <input
            id={input.name}
            className={classnames('custom-control-input', { 'is-invalid': touched && error })}
            {...input}
            {...attributes}
            onChange={(...args) => {
              input.onChange(...args);
              input.onBlur();
            }}
            type="checkbox"
            checked={!!input.value}
          />
          <span className="cr">
            <FA
              className="cr-icon"
              icon={faCheck}
              fixedWidth
            />
          </span>
          <Translate
            value={`${prefix}${input.name}.label`}
            className={classnames({ 'text-danger': touched && error })}
          />
          {/* {touched && error && */}
          {true && (
            <div className="invalid-feedback">
              <Translate value={`${prefix}${input.name}.${error}`} />
            </div>
          )}
        </label>
      </div>
    </FormGroup>
  );
};

SwitchInputComponent.propTypes = {
  input: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired,
  disabled: PropTypes.bool,
  i18nPath: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array,
  ]),
};

SwitchInputComponent.defaultProps = {
  i18nPath: '',
  disabled: false,
};

class SwitchInput extends Component {
  constructor(props) {
    super(props);
    this.validators = [
      this.validateRequired.bind(this),
    ];
  }

  validateRequired(v) {
    const { required } = this.props;
    return !required || v ? undefined : 'required';
  }

  render() {
    const { required, ...props } = this.props;
    return (
      <Field
        component={SwitchInputComponent}
        validate={this.validators}
        {...props}
      />
    );
  }
}

SwitchInput.propTypes = {
  required: PropTypes.bool,
};

SwitchInput.defaultProps = {
  required: false,
};

export default SwitchInput;
