/**
 * IMPORTS
 */

import {
  call,
  put,
  takeEvery,
} from 'redux-saga/effects';

import fetchLocation from '../utils/getLocation';
import { getWeather as fetchWeather } from '../utils/weather';

/**
 * CORE
 */

function* getLocation() {
  try {
    const location = yield call(fetchLocation);
    yield put({ type: 'GET_LOCATION_SUCCESS', location });
  } catch (error) {
    yield put({ type: 'GET_LOCATION_FAILURE', error });
  }
}

function* getWeather({ location }) {
  try {
    const { code } = yield call(fetchWeather, location);
    yield put({ type: 'GET_WEATHER_SUCCESS', code: parseInt(code, 10) });
  } catch (error) {
    yield put({ type: 'GET_WEATHER_FAILURE', error });
  }
}

export default [
  takeEvery('GET_LOCATION_REQUEST', getLocation),
  takeEvery('GET_WEATHER_REQUEST', getWeather),
];
