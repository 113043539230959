/**
 * CORE
 */

export const completeStep = step => (
  {
    type: 'COMPLETE_STEP',
    step,
  }
);

export const cancelStep = step => (
  {
    type: 'CANCEL_STEP',
    step,
  }
);

export const changeStep = (from, to, initiator = undefined) => (
  {
    type: 'CHANGE_STEP_REQUEST',
    from,
    to,
    initiator, // action type that initiated the request
  }
);
