/**
 * IMPORTS
 */

import _ from 'lodash';

/**
 * CORE
 */

const initial = {
  clients: {
    allIds: [],
    byId: {},
    byProject: {},
  },
  requests: [],
};

export default (state = initial, action) => {
  switch (action.type) {
    case 'INITIALIZE_CLIENT_REQUEST':
      return {
        ...state,
        clients: {
          ...state.clients,
          allIds: _.uniq([
            ...state.clients.allIds,
            action.appId,
          ]),
          byId: {
            ...state.clients.byId,
            [action.appId]: {
              appId: action.appId,
              initializing: true,
              initialized: false,
            },
          },
        },
      };

    case 'INITIALIZE_CLIENT_SUCCESS':
      return {
        ...state,
        clients: {
          ...state.clients,
          byId: {
            ...state.clients.byId,
            [action.appId]: {
              appId: action.appId,
              initializing: false,
              initialized: true,
              client: action.client,
              projectId: action.projectId,
            },
          },
          byProject: {
            ...state.clients.byProject,
            [action.projectId]: action.appId,
          },
        },
      };

    case 'INITIALIZE_CLIENT_FAILURE':
      return {
        ...state,
        clients: {
          ...state.clients,
          byId: {
            ...state.clients.byId,
            [action.appId]: {
              ...state.clients.byId[action.appId],
              initializing: false,
              initialized: false,
            },
          },
        },
      };

    case 'API_CALL_REQUEST':
      return {
        ...state,
        requests: [
          ...state.requests,
          { apiRequestId: action.apiRequestId },
        ],
      };

    case 'API_CALL_SUCCESS':
    case 'API_CALL_FAILURE':
    case 'API_CALL_CANCELLED':
      return {
        ...state,
        requests: _.filter(
          state.requests,
          ({ apiRequestId }) => apiRequestId !== action.apiRequestId,
        ),
      };

    default:
      return state;
  }
};
