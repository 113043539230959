/**
 * IMPORTS
 */

import fetch from 'unfetch';
import _ from 'lodash';

/**
 * CONSTANTS
 */

const FIELDS = [
  'city',
  'country',
  'countryCode',
  'lat',
  'lon',
  'timezone',
  'zip',
];

/**
 * CORE
 */

export default async () => {
  const res = await fetch('http://ip-api.com/json');
  const data = await res.json();
  if (data.status !== 'success') {
    throw new Error(data.message);
  }
  return _.pick(data, FIELDS);
};
