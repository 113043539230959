/* global window document */
/* eslint-disable react/jsx-filename-extension */

/**
 * IMPORTS
 */

import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { I18n } from 'react-i18nify';
import { composeWithDevTools } from 'redux-devtools-extension';
import { throttle } from 'lodash';
import { init, configureScope } from '@sentry/browser';

import App from './App';
import reducer from './reducers';
import rootSaga from './sagas';
import getTranslations from './utils/getTranslations';
import * as serviceWorker from './serviceWorker';
import { loadState, saveState } from './utils/state-storage';

import './css/index.scss';

/**
 * GLOBALS
 */

const {
  NODE_ENV,
  REACT_APP_VERSION,
  REACT_APP_LANGUAGES,
  REACT_APP_SENTRY_DSN,
} = process.env;

/**
 * SENTRY
 */

if (REACT_APP_SENTRY_DSN) {
  init({
    dsn: REACT_APP_SENTRY_DSN,
    environment: NODE_ENV,
  });

  configureScope((scope) => {
    scope.setTag('version', REACT_APP_VERSION);
  });
}

/**
 * CORE
 */

getTranslations()
  .then((translations) => {
    I18n.setTranslations(translations);
    I18n.setLocale(REACT_APP_LANGUAGES.split(',')[0]);
  });

const isProd = NODE_ENV === 'production';

const sagaMiddleware = createSagaMiddleware();
const middlewares = [sagaMiddleware];
const store = createStore(
  reducer,
  loadState(),
  isProd
    ? applyMiddleware(...middlewares)
    : composeWithDevTools(applyMiddleware(...middlewares)),
);

store.subscribe(
  throttle(() => saveState(store.getState())),
  5000,
);

sagaMiddleware.run(rootSaga);

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById('root'),
);

serviceWorker.register({
  onUpdate() {
    window.shouldReloadApp = true;
  },
});
