/* global window */

/**
 * IMPORTS
 */

import React, { Children, Component } from 'react';
import PropTypes from 'prop-types';

/**
 * ORIENTATION
 */

export const Orientation = ({ orientation, children, className }) => (
  <div className={`${className} react-orientation react-orientation--${orientation}`}>
    {children}
  </div>
);

Orientation.propTypes = {
  // alwaysRender: PropTypes.bool,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  orientation: PropTypes.oneOf(['portrait', 'landscape']).isRequired,
};

Orientation.defaultProps = {
  className: '',
  // alwaysRender: true,
};

/**
 * CORE
 */

window.screen.lockOrientationUniversal = window.screen.lockOrientation
|| window.screen.mozLockOrientation
|| window.screen.msLockOrientation;

const lock = (orientation) => {
  const { screen } = window;
  if (screen.orientation && typeof screen.orientation.lock === 'function') {
    return screen.orientation.lock(orientation);
  }

  if (screen.lockOrientationUniversal && screen.lockOrientationUniversal(orientation)) {
    return Promise.resolve();
  }

  return Promise.reject();
};

export default class DeviceOrientation extends Component {
  constructor(props) {
    super(props);

    const { lockOrientation } = props;
    this.lockOrientation(lockOrientation);
    this.onOrientationChange = this.onOrientationChange.bind(this);

    this.state = { orientation: null };
  }

  componentWillMount() {
    this.onOrientationChange(null);
  }

  componentDidMount() {
    const { screen } = window;
    if (screen.orientation && ('onchange' in screen.orientation)) {
      screen.orientation.addEventListener('change', this.onOrientationChange);
    } else if ('onorientationchange' in window) {
      window.addEventListener('orientationchange', this.onOrientationChange);
    } else {
      console.warn('No orientationchange events');
    }
  }

  componentWillUnmount() {
    const { screen } = window;
    if (screen.orientation && ('onchange' in screen.orientation)) {
      screen.orientation.removeEventListener('change', this.onOrientationChange);
    } else if ('onorientationchange' in window) {
      window.removeEventListener('orientationchange', this.onOrientationChange);
    }
  }

  onOrientationChange(/* event */) {
    const { screen } = window;
    const screenOrientation = screen.orientation || screen.msOrientation || screen.mozOrientation;
    let orientation = 'portrait';
    let type = 'primary';
    let { angle } = screenOrientation || { angle: 0 };
    if (screenOrientation) {
      [orientation, type] = screenOrientation.type.split('-');
    } else if (window.orientation) {
      angle = window.orientation;
      orientation = Math.abs(angle) === 90 ? 'landscape' : 'portrait';
    }

    this.setState({ orientation });
    this.props.onOrientationChange(orientation, type, angle);
  }

  lockOrientation(orientation) {
    if (typeof orientation !== 'string') {
      return;
    }

    return lock(orientation)
      .then(() => this.props.onLockOrientation(true))
      .catch(() => this.props.onLockOrientation(false));
  }

  render() {
    const { children, className } = this.props;
    const { orientation } = this.state;
    return (
      <div className={className}>
        {
          Children.map(children, (child) => {
            const { props } = child;
            if (props.alwaysRender || props.orientation === orientation) {
              return child;
            }
          })
        }
      </div>
    );
  }
}

// https://developer.mozilla.org/en-US/docs/Web/API/screen/lockOrientation
const LOCK_ORIENTATIONS = [
  'portrait-primary',
  'portrait-secondary',
  'landscape-primary',
  'landscape-secondary',
  'portrait',
  'landscape',
  'default',
];

const isOrientation = (props, propName, componentName, location, propFullName) => {
  const propValue = props[propName];
  if (propValue.type !== Orientation) {
    return new Error(`Invalid ${location} '${propFullName}' supplied to '${componentName}', expected 'Orientation' component.`);
  }
};

const noop = () => false;

DeviceOrientation.propTypes = {
  children: PropTypes.oneOfType([
    isOrientation,
    PropTypes.arrayOf(isOrientation),
  ]).isRequired,
  className: PropTypes.string,
  lockOrientation: PropTypes.oneOfType([
    PropTypes.oneOf(LOCK_ORIENTATIONS),
    PropTypes.arrayOf(PropTypes.oneOf(LOCK_ORIENTATIONS)),
  ]),
  onLockOrientation: PropTypes.func,
  onOrientationChange: PropTypes.func,
};

DeviceOrientation.defaultProps = {
  className: '',
  lockOrientation: null,
  onLockOrientation: noop,
  onOrientationChange: noop,
};
