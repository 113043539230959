/**
 * IMPORTS
 */

import _ from 'lodash';

/**
 * CORE
 */

const initial = {
  attempts: 0,
  selfies: [],
  triggered: false,
  validated: false,
  cancelled: false,
  uploadsRequested: false,
  leafRequested: false,
};

export default (state = initial, action) => {
  switch (action.type) {
    case 'CLEAR_SESSION':
      return initial;

    case 'ADD_SELFIE_BLOB':
      return {
        ...state,
        selfies: [
          ...state.selfies,
          action.blob,
        ],
      };

    case 'PICK_SELFIE_BLOB':
      return {
        ...state,
        selfies: [],
        selfie: action.blob,
      };

    case 'ADD_SESSION_DATA':
      return {
        ...state,
        data: {
          ...state.data,
          ...action.data,
        },
      };

    case 'SET_SESSION_FRAME':
      return {
        ...state,
        frame: _.pick(action, 'scale', 'center'),
      };

    case 'RESET_SESSION_FRAME':
      return _.omit(state, 'frame');

    case 'CREATE_SESSION_SUCCESS':
      return {
        ...initial,
        ..._.pick(action.session, [
          'id',
          'project',
          'maxAttempts',
          'expectedPhotos',
        ]),
      };

    case 'TRIGGER_SESSION_SUCCESS':
      return {
        ...state,
        triggered: true,
        attempts: state.attempts + 1,
      };

    case 'GET_UPLOAD_STATUS_REQUEST':
      return {
        ...state,
        uploadsRequested: true,
      };

    case 'GET_UPLOAD_STATUS_SUCCESS':
      return {
        ...state,
        uploadsRequested: false,
        uploads: action.uploads
          .map(u => _.pick(u, 'index', 'progress')),
      };

    case 'GET_UPLOAD_STATUS_FAILURE':
      return {
        ...state,
        uploadsRequested: false,
      };

    case 'GET_SESSION_LEAF_REQUEST':
      return {
        ...state,
        leafRequested: true,
      };

    case 'GET_SESSION_LEAF_SUCCESS':
      return {
        ...state,
        leafRequested: false,
        leaf: action.leaf,
      };

    case 'GET_SESSION_LEAF_FAILURE':
      return {
        ...state,
        leafRequested: false,
      };

    case 'RETRY_SESSION_SUCCESS':
      return {
        ..._.omit(state, [
          'selfie',
          'uploads',
        ]),
        leaf: _.get(action, 'session.leaf'),
        selfies: [],
        triggered: false,
      };

    case 'CANCEL_SESSION_SUCCESS':
      return {
        ...state,
        cancelled: true,
      };

    case 'VALIDATE_SESSION_SUCCESS':
      return {
        ...state,
        validated: true,
      };

    default:
      return state;
  }
};
