/**
 * IMPORTS
 */

import { all } from 'redux-saga/effects';

import apiSagas from './api-sagas';
import appSagas from './app-sagas';
import failuresSagas from './failures-sagas';
import navSagas from './nav-sagas';
import projectSagas from './project-sagas';
import thirdPartySagas from './third-party-sagas';
import soundSagas from './sound-sagas';
import debugSagas from './debug-sagas';
import queueSagas from './queue-sagas';

/**
 * CORE
 */

export default function* () {
  yield all([
    ...apiSagas,
    ...thirdPartySagas,
    ...appSagas,
    ...failuresSagas,
    ...navSagas,
    ...projectSagas,
    ...soundSagas,
    ...debugSagas,
    ...queueSagas,
  ]);
}
