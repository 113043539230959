/**
 * IMPORTS
 */

const { v4: uuid } = require('uuid');

/**
 * CORE
 */

export const enqueueAction = (action, typePrefix, priority = 0) => (
  {
    type: 'ENQUEUE_ACTION',
    itemId: uuid(),
    action,
    typePrefix,
    priority,
  }
);

export const dequeueAction = itemId => (
  {
    type: 'DEQUEUE_ACTION',
    itemId,
  }
);

export const attemptAction = itemId => (
  {
    type: 'ATTEMPT_ACTION',
    itemId,
  }
);
