/* global window */

/**
 * IMPORTS
 */

import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Sound from 'react-sound';

import { stopSound } from '../actions/sound-actions';

/**
 * CORE
 */

window.soundManager.setup({ debugMode: false });

const SoundPlayer = ({ sounds, playing, stopSound: stop }) => (
  <Fragment>
    {sounds.map(({ id, url }) => (
      <Sound
        key={id}
        url={url}
        playStatus={playing[id] ? Sound.status.PLAYING : Sound.status.STOPPED}
        onFinishedPlaying={() => stop(id)}
        autoLoad
      />
    ))}
  </Fragment>
);

SoundPlayer.propTypes = {
  sounds: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
    }),
  ).isRequired,
  playing: PropTypes.object.isRequired,
  stopSound: PropTypes.func.isRequired,
};

export default connect(
  ({ sound: { sounds, playing } }) => ({ sounds, playing }),
  { stopSound },
)(SoundPlayer);
