/**
 * IMPORTS
 */

import _ from 'lodash';
import { createSelector } from 'reselect';
import { findCode } from '../utils/weather';

/**
 * APP SELECTORS
 */

export const getMeanCenter = ({ app: { meanCenter } }) => meanCenter;

export const getLocale = ({ app: { locale } }) => locale;

export const getLocation = ({ app: { location } }) => location;

export const getStep = ({ app: { step } }) => step;

export const getStepDisabled = ({ app: { isTransiting } }) => isTransiting;

export const getWeatherCode = ({ app: { weatherCode } }) => weatherCode;

export const getWeather = createSelector(
  getWeatherCode,
  code => (code ? findCode(code) : null),
);

export const getCurrentProjectId = ({ app: { project } }) => project;

export const getOnlineStatus = ({ app: { online } }) => online;

export const isOnlineRequested = ({ app: { onlineRequested } }) => onlineRequested;

/**
 * PROJECT SELECTORS
 */

const getProjects = ({ projects }) => projects;

export const getAllProjects = createSelector(
  getProjects,
  projects => Object.keys(projects).map(k => projects[k]),
);

export const getAllProjectIds = createSelector(
  getAllProjects,
  projects => projects.map(({ id }) => id),
);

export const getCurrentProject = createSelector(
  getProjects,
  getCurrentProjectId,
  (projects, projectId) => projects[projectId],
);

export const getCurrentProjectTTLs = createSelector(
  getCurrentProject,
  project => project.ttls,
);

export const getCurrentProjectStatus = createSelector(
  getCurrentProject,
  project => _.get(project, 'status', {
    isOK: false,
    projectIsEnabled: false,
    disabledMessage: 'Project is disabled',
  }),
);

export const getCurrentProjectInitializingStatus = createSelector(
  getCurrentProject,
  project => _.get(project, 'initializing', true),
);

export const getCurrentProjectInitializedStatus = createSelector(
  getCurrentProject,
  project => _.get(project, 'initialized', false),
);

export const getCurrentFormFields = createSelector(
  getCurrentProject,
  project => _.get(project, 'form.fields', []),
);

export const getCurrentFormBooleanFields = createSelector(
  getCurrentFormFields,
  fields => fields.filter(({ type }) => type === 'boolean'),
);

export const getCurrentFormEmailFields = createSelector(
  getCurrentFormFields,
  fields => fields.filter(({ type }) => type === 'email'),
);

export const getCurrentFormDefaults = createSelector(
  getCurrentFormBooleanFields, // Only booleans have defaults at the moment
  fields => fields.reduce((acc, { name, default: v }) => ({ ...acc, [name]: v }), {}),
);

export const getCurrentProjectMetadata = createSelector(
  getCurrentProject,
  project => _.get(project, 'metadata', []),
);

/**
 * API SELECTORS
 */

const getClients = ({ api: { clients } }) => clients;

export const getAllClients = createSelector(
  getClients,
  clients => clients.allIds.map(id => clients.byId[id]),
);

export const getClientSelector = createSelector(
  getClients,
  clients => projectId => _.get(clients.byId, clients.byProject[projectId]),
);

export const getCurrentClient = createSelector(
  getClients,
  getCurrentProjectId,
  (clients, projectId) => _.get(clients.byId, clients.byProject[projectId]),
);

// needs disable caching because 'client' is an instance of class
export const getCurrentToken = (state) => {
  const { api: { clients } } = state;
  const projectId = getCurrentProjectId(state);
  const { client } = _.get(clients.byId, clients.byProject[projectId]);
  return client.token;
};

export const getOngoingRequests = ({ api: { requests } }) => requests;

export const getRequestingStatus = createSelector(
  getOngoingRequests,
  requests => requests.length > 0,
);

/**
 * SESSION SELECTORS
 */

export const getSession = ({ session }) => session;

export const getSessionData = createSelector(
  getSession,
  session => session.data || {},
);

export const getSessionFrame = createSelector(
  getSession,
  session => session.frame,
);

export const getSessionLeaf = createSelector(
  getSession,
  session => session.leaf,
);

export const getSessionInitialFrame = createSelector(
  getSessionFrame,
  getMeanCenter,
  getSessionLeaf,
  (sessionFrame, meanCenter, leaf) => ({
    scale: 1,
    ...leaf,
    ...sessionFrame,
    center: {
      x: 0.5,
      y: 0.5,
      ...!!leaf && leaf.center,
      ...meanCenter,
      ...!!sessionFrame && sessionFrame.center,
    },
  }),
);

export const getSessionLastAttemptStatus = createSelector(
  getSession,
  session => session.attempts >= session.maxAttempts,
);

export const getSessionSelfies = createSelector(
  getSession,
  session => session.selfies,
);

export const getFormInitialValues = createSelector(
  getLocale,
  getCurrentFormDefaults,
  getSessionData,
  (locale, defaults, data) => ({
    locale,
    ...defaults,
    ...data,
  }),
);

/**
 * QUEUED REQUESTS SELECTORS
 */

const QUEUE_CONCURRENCY = 5;

const minPriority = items => Math.min(...items.map(({ priority }) => priority));

const filterPriority = (items) => {
  const minP = minPriority(items);
  return items.filter(({ priority }) => priority === minP);
};

export const getQueuedItems = ({ queue }) => queue;

export const getIdleQueuedItems = createSelector(
  getQueuedItems,
  getAllProjectIds,
  items => items.filter(({ running }) => !running),
);

export const getIdleQueuedItemsCount = createSelector(
  getIdleQueuedItems,
  items => items.length,
);

export const getRunningQueuedItems = createSelector(
  getQueuedItems,
  getAllProjectIds,
  items => items.filter(({ running }) => !!running),
);

export const getRunningQueuedItemsCount = createSelector(
  getRunningQueuedItems,
  items => items.length,
);

export const getRunningPriorityPerSession = createSelector(
  getRunningQueuedItems,
  items => (
    _(items)
      .groupBy(({ action: { sessionId } }) => sessionId)
      .mapValues(minPriority)
      .value()
  ),
);

export const getCandidateQueuedItems = createSelector(
  getIdleQueuedItems,
  getAllProjectIds,
  (items, projectIds) => (
    items.filter(({ action: { projectId } }) => projectIds.includes(projectId))
  ),
);

export const getPriorCandidateQueuedItems = createSelector(
  getCandidateQueuedItems,
  getRunningPriorityPerSession,
  // get prioritized items per session
  (candidates, priorities) => (
    _(candidates)
      .groupBy(({ action: { sessionId } }) => sessionId)
      // remove items with priority > currently *running* priority (for that session)
      .mapValues((items, sessionId) => (
        items.filter(i => i.priority <= _.get(priorities, sessionId, Number.POSITIVE_INFINITY))
      ))
      // transform into array of arrays (one array per session)
      .values()
      // retrieve only the items with the minimum priority of that set
      .map(filterPriority)
      .flatten()
      .sortBy(({ nextAttempt }) => nextAttempt)
      .value()
  ),
);

export const getAttemptableQueuedItems = (state) => {
  const takeCount = Math.max(0, QUEUE_CONCURRENCY - getRunningQueuedItemsCount(state));
  if (takeCount === 0) {
    return [];
  }

  const items = getPriorCandidateQueuedItems(state);
  const now = Date.now();
  return items
    .filter(({ nextAttempt }) => nextAttempt <= now)
    .slice(0, takeCount);
};

export const getQueuedItemSelector = createSelector(
  getQueuedItems,
  items => itemId => _.find(items, item => item.itemId === itemId),
);

/**
 * OVERRIDABLE PROCESS ENV. SELECTORS
 */

const getOverridableProcessEnvValue = (
  key,
  metadata,
  {
    transform = v => v,
    validate = () => true,
    defaultValue,
  },
) => {
  const entry = metadata.find(e => e.key === key);
  if (entry) {
    const value = transform(entry.value);
    if (validate(value)) {
      return value;
    }
  }
  const envKey = `REACT_APP_${key}`;
  if (envKey in process.env) {
    const value = transform(process.env[envKey]);
    if (validate(value)) {
      return value;
    }
  }
  return defaultValue;
};

export const getCountdownDuration = createSelector(
  getCurrentProjectMetadata,
  metadata => (
    getOverridableProcessEnvValue('COUNTDOWN_DURATION', metadata, {
      transform: v => parseInt(v, 10),
      validate: v => !Number.isNaN(v),
      defaultValue: 3,
    })
  ),
);

export const getDemoUrl = createSelector(
  getCurrentProjectMetadata,
  metadata => (
    getOverridableProcessEnvValue('DEMO_URL', metadata, {
      validate: v => /^https?:\/\//.test(v),
    })
  ),
);

export const getCameraLocationUrl = createSelector(
  getCurrentProjectMetadata,
  metadata => (
    getOverridableProcessEnvValue('CAMERA_LOCATION_URL', metadata, {
      validate: v => /^https?:\/\//.test(v),
    })
  ),
);

export const getPreviewIsDisabled = createSelector(
  getCurrentProjectMetadata,
  metadata => (
    getOverridableProcessEnvValue('DISABLE_PREVIEW', metadata, {
      transform: v => JSON.parse(v),
      validate: v => typeof v === 'boolean',
      defaultValue: false,
    })
  ),
);

export const getWeatherIsEnabled = createSelector(
  getCurrentProjectMetadata,
  metadata => (
    getOverridableProcessEnvValue('ENABLE_WEATHER', metadata, {
      transform: v => JSON.parse(v),
      validate: v => typeof v === 'boolean',
      defaultValue: false,
    })
  ),
);

export const getDataUpdateIsEnabled = createSelector(
  getCurrentProjectMetadata,
  metadata => (
    getOverridableProcessEnvValue('ENABLE_DATA_UPDATE', metadata, {
      transform: v => JSON.parse(v),
      validate: v => typeof v === 'boolean',
      defaultValue: true,
    })
  ),
);

export const getQueuedItemsMaxAttempts = createSelector(
  getCurrentProjectMetadata,
  metadata => (
    getOverridableProcessEnvValue('QUEUED_ITEMS_MAX_ATTEMPTS', metadata, {
      transform: v => parseInt(v, 10),
      validate: v => !Number.isNaN(v),
      defaultValue: 10,
    })
  ),
);

export const getLogEventsIsEnabled = createSelector(
  getCurrentProjectMetadata,
  metadata => (
    getOverridableProcessEnvValue('ENABLE_LOG_EVENTS', metadata, {
      transform: v => JSON.parse(v),
      validate: v => typeof v === 'boolean',
      defaultValue: false,
    })
  ),
);

export const getPreviewAngle = createSelector(
  getCurrentProjectMetadata,
  metadata => (
    getOverridableProcessEnvValue('PREVIEW_ROTATION', metadata, {
      transform: v => parseFloat(v),
      validate: v => !Number.isNaN(v),
      defaultValue: 0.0,
    })
  ),
);

export const getTextsUrl = createSelector(
  getCurrentProjectMetadata,
  metadata => (
    getOverridableProcessEnvValue('TEXTS_URL', metadata, {
      validate: v => /^https?:\/\//.test(v),
    })
  ),
);
