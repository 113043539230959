/**
 * IMPORTS
 */

import fetch from 'unfetch';
import _ from 'lodash';
import { encode } from 'querystring';

/**
 * CONSTANTS
 */

const BASE_URI = 'https://query.yahooapis.com/v1/public/yql';

/**
 * CORE
 */

export default async (location, unit = 'c') => {
  const query = {
    q: [
      'SELECT item.condition FROM weather.forecast',
      `WHERE u='${unit}'`,
      `AND woeid IN (SELECT woeid FROM geo.places(1) WHERE text="${location}")`,
    ].join(' '),
    format: 'json',
  };

  const res = await fetch(`${BASE_URI}?${encode(query)}`);
  if (res.status >= 400) {
    throw new Error('Bad response from server');
  }

  const data = await res.json();
  return _.get(data, 'query.results.channel.item.condition', null);
};
