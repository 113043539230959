/**
 * CORE
 */

export const playSound = sound => (
  {
    type: 'PLAY_SOUND',
    sound,
  }
);

export const stopSound = sound => (
  {
    type: 'STOP_SOUND',
    sound,
  }
);
