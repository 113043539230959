/**
 * IMPORTS
 */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import OpenSeadragon from 'openseadragon';
import { FontAwesomeIcon as FA } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

/**
 * Note: to handle non-16/9 ratio we transform viewerWidth like this:
 * (vw / ratio) * (1280 / 720)
 * when "composed" with 1280 it therefore can be simplified to this:
 * 1280 / ((vw / ratio) * (1280 / 720)) === (ratio * 720) / vw
 */

/**
 * CORE
 */

class ZoomPanImage extends Component {
  constructor(props) {
    super(props);

    this.viewer = null;
    this.state = {
      viewportIsLoaded: false,
      tileIsLoaded: false,
      imageSize: null,
      scale: this.props.scale,
      center: this.props.center,
    };
  }

  componentDidMount() {
    this.initSeaDragon();
  }

  componentWillReceiveProps({ src, scale, center }) {
    if (src !== this.props.src) {
      this.viewer.close();
      this.setState({ scale, center }, () => this.viewer.open(src));
    } else {
      const centerChanged = center.x !== this.props.center.x || center.y !== this.props.center.y;
      if (scale !== this.props.scale || centerChanged) {
        this.setState({ scale, center }, () => this.stateToViewport());
      }
    }
  }

  componentWillUnmount() {
    this.viewer.close();
    this.viewer = null;
  }

  onViewportOpen() {
    const { world, element } = this.viewer;
    this.setState({
      viewportIsLoaded: true,
      imageSize: world.getItemAt(0).source.dimensions,
      viewerWidth: element.offsetWidth, // has to be on open (might change with resize)
    }, () => this.stateToViewport());
  }

  onViewportClose() {
    this.setState({ viewportIsLoaded: false });
  }

  onViewportZoom({ zoom }) {
    const center = this.viewer.viewport.getCenter();
    return this.onViewportChange(zoom, center);
  }

  onViewportPan({ center }) {
    const zoom = this.viewer.viewport.getZoom();
    return this.onViewportChange(zoom, center);
  }

  onViewportChange(zoom, center) {
    if (!this.state.viewportIsLoaded) {
      return false;
    }

    const { ratio } = this.props;
    const { imageSize: { x: imgWidth, y: imgHeight }, viewerWidth } = this.state;
    const { viewport } = this.viewer;
    // see top note
    const scale = viewport.viewportToImageZoom(zoom) * ((ratio * 720) / viewerWidth);
    const imageCenter = viewport.viewportToImageCoordinates(center);
    const newCenter = {
      x: imageCenter.x / imgWidth,
      y: imageCenter.y / imgHeight,
    };

    this.props.onChange({ scale, center: newCenter });
    this.setState({ scale, center: newCenter });

    return true;
  }

  onTileLoaded() {
    const { onLoad } = this.props;
    this.setState(
      { tileIsLoaded: true },
      () => { if (onLoad) onLoad(); },
    );
  }

  initSeaDragon() {
    const { rotation } = this.props;
    const viewer = OpenSeadragon({
      id: 'openSeaDragon-viewer',
      degrees: rotation,
      immediateRender: true,
      visibilityRatio: 1.0,
      constrainDuringPan: rotation === 0, // feels really buggy otherwise
      autoResize: false,
      // defaultZoomLevel: 0,
      minZoomLevel: 1,
      // maxZoomLevel: 10,
      zoomPerScroll: 1.1,
      showNavigator: false,
      showNavigationControl: false,
      animationTime: 0,
      tileSources: this.props.src,
      gestureSettingsMouse: {
        flickEnabled: false, // prevent jumps at end of drag
        scrollToZoom: false,
        clickToZoom: false,
        dblClickToZoom: false,
        pinchToZoom: false,
      },
      gestureSettingsTouch: {
        flickEnabled: false, // prevent jumps at end of drag
        scrollToZoom: false,
        clickToZoom: false,
        dblClickToZoom: false,
        pinchToZoom: false,
      },
      gestureSettingsUnknown: {
        flickEnabled: false, // prevent jumps at end of drag
        scrollToZoom: false,
        clickToZoom: false,
        dblClickToZoom: false,
        pinchToZoom: false,
      },
    });

    viewer.addHandler('pan', this.onViewportPan.bind(this));
    viewer.addHandler('zoom', this.onViewportZoom.bind(this));
    viewer.addHandler('open', this.onViewportOpen.bind(this));
    viewer.addHandler('close', this.onViewportClose.bind(this));
    viewer.addOnceHandler('tile-drawn', this.onTileLoaded.bind(this));

    this.viewer = viewer;
  }

  stateToViewport() {
    const { viewport } = this.viewer;
    const { scale, center, imageSize: { x: imgWidth, y: imgHeight }, viewerWidth } = this.state;
    const { ratio } = this.props;

    const viewportZoom = viewport
      .imageToViewportZoom(scale / ((ratio * 720) / viewerWidth)); // see top note
    const viewportCenter = viewport.imageToViewportCoordinates(
      center.x * imgWidth,
      center.y * imgHeight,
    );

    /**
     * hacky way to replace panTo and zoomTo (to avoid pan/zoom events)
     * Original:
     * - viewport.zoomTo(viewportZoom);
     * - viewport.panTo(viewportCenter);
     */

    viewport.zoomSpring.resetTo(viewportZoom);
    viewport.centerSpringX.resetTo(viewportCenter.x);
    viewport.centerSpringY.resetTo(viewportCenter.y);
  }

  render() {
    const { ratio } = this.props;
    const { viewportIsLoaded, tileIsLoaded } = this.state;
    const showSpinner = !viewportIsLoaded || !tileIsLoaded;
    return (
      <div
        id="openSeaDragon-viewer"
        style={{
          display: 'block',
          position: 'relative',
          width: 720 * ratio,
          height: 720,
          maxWidth: '80vmin',
          maxHeight: `calc(80vmin * ${1 / ratio})`,
          margin: 'auto',
        }}
      >
        {showSpinner && (
          <div
            style={{
              width: '100%',
              height: '100%',
              position: 'relative',
            }}
          >
            <div
              className="text-center"
              style={{
                position: 'absolute',
                top: '50%',
                transform: 'translateY(-50%)',
                width: '100%',
              }}
            >
              <FA icon={faSpinner} pulse size="3x" fixedWidth />
            </div>
          </div>
        )}
        {this.state.viewportIsLoaded && this.props.overlay}
      </div>
    );
  }
}

ZoomPanImage.propTypes = {
  onChange: PropTypes.func.isRequired,
  onLoad: PropTypes.func,
  src: PropTypes.string.isRequired,
  scale: PropTypes.number.isRequired,
  center: PropTypes.shape({
    x: PropTypes.number.isRequired,
    y: PropTypes.number.isRequired,
  }).isRequired,
  ratio: PropTypes.number,
  overlay: PropTypes.node,
  rotation: PropTypes.number,
};

ZoomPanImage.defaultProps = {
  ratio: 1280 / 720,
  overlay: undefined,
  onLoad: undefined,
  rotation: 0,
};

export default ZoomPanImage;
