/* global window */

/**
 * IMPORTS
 */

import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  Modal,
  ModalBody,
  ModalFooter,
  Button,
  Card,
  CardBody,
} from 'reactstrap';
import { FontAwesomeIcon as FA } from '@fortawesome/react-fontawesome';
import {
  faArrowRight,
  faTimes,
  faCheck,
  faPlay,
  faBars,
  faEye,
} from '@fortawesome/free-solid-svg-icons';
import { Translate } from 'react-i18nify';
import PinchZoomPan from 'react-responsive-pinch-zoom-pan';
import _ from 'lodash';
import 'weathericons/css/weather-icons.min.css';

import {
  completeStep,
  cancelStep,
} from '../../actions/nav-actions';
import { setLocale } from '../../actions/app-actions';
import {
  getStepDisabled,
  getWeather,
  getRequestingStatus,
  getAllProjects,
  getDemoUrl,
  getCameraLocationUrl,
  getTextsUrl,
} from '../../selectors';
import getTranslations from '../../utils/getTranslations';

import logo from '../../assets/logo.png';

/**
 * CORE
 */

class Home extends Component {
  static stepId = 'home';

  static propTypes = {
    weather: PropTypes.object,
    shouldReload: PropTypes.bool,
    isRequesting: PropTypes.bool,
    disabled: PropTypes.bool,
    videoDemo: PropTypes.string,
    cameraLocation: PropTypes.string,
    canPickProjects: PropTypes.bool,
    completeStep: PropTypes.func.isRequired,
    cancelStep: PropTypes.func.isRequired,
    setLocale: PropTypes.func.isRequired,
    textsUrl: PropTypes.string,
  };

  static defaultProps = {
    disabled: false,
    weather: null,
    shouldReload: false,
    isRequesting: false,
    videoDemo: undefined,
    cameraLocation: undefined,
    canPickProjects: false,
    textsUrl: undefined,
  };

  constructor(props) {
    super(props);

    this.state = {
      weatherModal: false,
      videoModal: false,
      cameraLocationModal: false,
      translations: null,
    };

    this.onButtonClick = this.onButtonClick.bind(this);
    this.onBackButtonClick = this.onBackButtonClick.bind(this);
    this.onWeatherToggle = this.onWeatherToggle.bind(this);
    this.onVideoModalToggle = this.onVideoModalToggle.bind(this);
    this.onCameraLocationModalToggle = this.onCameraLocationModalToggle.bind(this);
  }

  async componentWillMount() {
    const translations = await getTranslations(this.props.textsUrl);
    this.setState({ translations });
  }

  onButtonClick(locale) {
    this.props.setLocale(locale);

    const { weather } = this.props;
    if (weather && weather.warning) {
      this.setState({ weatherModal: true });
    } else {
      this.props.completeStep(Home.stepId);
    }
  }

  onBackButtonClick() {
    this.props.cancelStep(Home.stepId);
  }

  onWeatherToggle() {
    const { weatherModal } = this.state;
    this.setState({ weatherModal: !weatherModal });
  }

  onVideoModalToggle() {
    const { videoModal } = this.state;
    this.setState({ videoModal: !videoModal });
  }

  onCameraLocationModalToggle() {
    const { cameraLocationModal } = this.state;
    this.setState({ cameraLocationModal: !cameraLocationModal });
  }

  renderButton(locale) {
    const { disabled } = this.props;
    const { translations } = this.state;
    if (!translations) {
      return null;
    }

    const text = _.get(translations, `${locale}.Home.take_selfie`, locale);
    return (
      <Button
        className="app-button mb-3"
        color="primary"
        size="lg"
        onClick={() => this.onButtonClick(locale)}
        disabled={disabled}
      >
        <div className="clearfix">
          <span>{text}</span>
          <span className="ml-2 float-right">
            <FA icon={faArrowRight} />
          </span>
        </div>
      </Button>
    );
  }

  renderDemoButton() {
    return (
      <Button
        outline
        color="primary"
        className="bottom-button demo-button"
        onClick={this.onVideoModalToggle}
      >
        <FA icon={faPlay} fixedWidth />
      </Button>
    );
  }

  renderCameraLocationButton() {
    return (
      <Button
        outline
        color="primary"
        className="bottom-button camera-button"
        onClick={this.onCameraLocationModalToggle}
      >
        <FA icon={faEye} fixedWidth />
      </Button>
    );
  }

  renderBackButton() {
    return (
      <Button
        outline
        color="primary"
        className="bottom-button back-button"
        onClick={this.onBackButtonClick}
      >
        <FA icon={faBars} fixedWidth />
      </Button>
    );
  }

  renderVideoModal() {
    const { videoDemo } = this.props;
    const { videoModal } = this.state;
    return (
      <Modal
        size="xl"
        className="transparent-modal"
        isOpen={videoModal}
        toggle={this.onVideoModalToggle}
        centered
      >
        <ModalBody style={{ padding: 0 }}>
          <button
            type="button"
            onClick={this.onVideoModalToggle}
            className="close-modal-button"
          >
            <FA icon={faTimes} />
          </button>
          <video
            preload="auto"
            style={{
              width: '100%',
              height: '100%',
            }}
            controls={false}
            autoPlay
            loop
            muted
          >
            <source
              src={videoDemo}
              type="video/mp4"
            />
          </video>
        </ModalBody>
      </Modal>
    );
  }

  renderCameraLocationModal() {
    const { cameraLocation } = this.props;
    const { cameraLocationModal } = this.state;
    return (
      <Modal
        size="xl"
        className="transparent-modal"
        isOpen={cameraLocationModal}
        toggle={this.onCameraLocationModalToggle}
        centered
      >
        <ModalBody style={{ padding: 0 }}>
          <button
            type="button"
            onClick={this.onCameraLocationModalToggle}
            className="close-modal-button"
          >
            <FA icon={faTimes} />
          </button>
          <div
            className="pinch-zoom-pan-container"
          >
            <PinchZoomPan
              position="center"
              zoomButtons={false}
            >
              <img
                alt="Camera Location"
                src={cameraLocation}
                style={{ pointerEvents: 'all' }}
              />
            </PinchZoomPan>
          </div>
        </ModalBody>
      </Modal>
    );
  }

  renderWeatherModal() {
    const { weather, disabled } = this.props;
    return (
      <Modal
        className="modal-dialog-centered"
        isOpen={this.state.weatherModal}
        toggle={this.onWeatherToggle}
      >
        <ModalBody
          className="d-flex justify-content-between align-items-center"
        >
          <div
            className="text-primary mr-3"
            style={{ fontSize: '4rem' }}
          >
            <i className={`wi wi-yahoo-${weather.code}`} />
          </div>
          <Translate value="Home.weather.warning" />
        </ModalBody>
        <ModalFooter>
          <Button
            color="secondary"
            className="app-button"
            onClick={this.onWeatherToggle}
            disabled={disabled}
            outline
          >
            <FA icon={faTimes} />
            &nbsp;
            <Translate value="Home.weather.close" />
          </Button>
          <Button
            color="primary"
            className="app-button"
            onClick={() => this.props.completeStep(Home.stepId)}
            disabled={disabled}
          >
            <FA icon={faCheck} />
            &nbsp;
            <Translate value="Home.weather.continue" />
          </Button>
        </ModalFooter>
      </Modal>
    );
  }

  render() {
    const {
      shouldReload,
      isRequesting,
      videoDemo,
      cameraLocation,
      canPickProjects,
    } = this.props;
    const { translations } = this.state;

    if (shouldReload && !isRequesting) {
      return (
        <Card className="text-center">
          <CardBody>
            <div>An update is available</div>
            <div>
              <Button
                className="app-button"
                color="primary"
                onClick={() => window.location.reload()}
              >
                Reload
              </Button>
            </div>
          </CardBody>
        </Card>
      );
    }

    return (
      <Fragment>
        {this.renderWeatherModal()}
        <div style={{ textAlign: 'center' }}>
          <div style={{ marginBottom: 70 }}>
            <img src={logo} alt="logo" className="logo" />
          </div>
          <div className="d-inline-block">
            {Object.keys(translations || {}).map(l => (
              <div key={l}>{this.renderButton(l)}</div>
            ))}
          </div>
        </div>
        {!!videoDemo && (
          <Fragment>
            {this.renderDemoButton()}
            {this.renderVideoModal()}
          </Fragment>
        )}
        {!!cameraLocation && (
          <Fragment>
            {this.renderCameraLocationButton()}
            {this.renderCameraLocationModal()}
          </Fragment>
        )}
        {canPickProjects && this.renderBackButton()}
      </Fragment>
    );
  }
}

export default connect(
  state => ({
    disabled: getStepDisabled(state),
    weather: getWeather(state),
    isRequesting: getRequestingStatus(state),
    shouldReload: !!window.shouldReloadApp,
    videoDemo: getDemoUrl(state),
    cameraLocation: getCameraLocationUrl(state),
    canPickProjects: (getAllProjects(state) || []).length > 1,
    textsUrl: getTextsUrl(state),
  }),
  {
    completeStep,
    cancelStep,
    setLocale,
  },
)(Home);
