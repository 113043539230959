/**
 * IMPORTS
 */

import _ from 'lodash';
import outliers from 'outliers';

/**
 * CORE
 */

const initial = {
  step: 'project-picker',
  project: null,
  isTransiting: false,
  isProjectReady: true,
  location: process.env.REACT_APP_LOCATION || null, // if null will try to auto-detect
  weatherCode: 3200, // unknown code
  locale: process.env.REACT_APP_LANGUAGES.split(',')[0],
  pastCenters: [],
  pastScales: [],
  asyncError: null,
  online: true,
  onlineRequested: false,
};

export default (state = initial, action) => {
  switch (action.type) {
    case 'ONLINE_REQUEST':
      return {
        ...state,
        onlineRequested: true,
      };

    case 'ONLINE_SUCCESS':
      return {
        ...state,
        online: true,
        onlineRequested: false,
      };

    case 'ONLINE_FAILURE':
      return {
        ...state,
        online: false,
        onlineRequested: false,
      };

    case 'PICK_PROJECT':
      return {
        ...state,
        project: action.projectId,
        isProjectReady: true,
        pastCenters: [],
        pastScales: [],
      };

    case 'UNPICK_PROJECT':
      return {
        ...state,
        project: undefined,
      };

    case 'SHOW_ASYNC_ERROR':
      return {
        ...state,
        asyncError: {
          requestType: action.asyncRequestType,
          message: _.get(action, 'error.message'),
        },
      };

    case 'HIDE_ASYNC_ERROR':
      return {
        ...state,
        asyncError: null,
      };

    case 'SET_LOCALE':
      return {
        ...state,
        locale: action.locale,
      };

    case 'GET_PROJECT_STATUS_SUCCESS': {
      if (action.projectId !== state.project) {
        return state;
      }

      const { isOK: isProjectReady } = action.status;
      const isSwitch = isProjectReady !== state.isProjectReady;
      return {
        ...state,
        isProjectReady,
        ...(isSwitch && { step: isProjectReady ? 'home' : 'not-ready' }),
      };
    }

    case 'CHANGE_STEP_REQUEST':
      return {
        ...state,
        isTransiting: true,
      };

    case 'CHANGE_STEP_SUCCESS':
      return {
        ...state,
        step: action.to,
        isTransiting: false,
      };

    case 'CHANGE_STEP_FAILURE':
      return {
        ...state,
        isTransiting: false,
      };

    case 'GET_LOCATION_SUCCESS':
      return {
        ...state,
        location: action.location.city,
      };

    case 'GET_WEATHER_SUCCESS':
      return {
        ...state,
        weatherCode: action.code,
      };

    case 'SET_SESSION_LEAF_SUCCESS': {
      const { center } = action.leaf;
      const pastCenters = [center, ...state.pastCenters].slice(0, 10);
      const centers = pastCenters.filter(outliers('x')).filter(outliers('y'));
      return {
        ...state,
        pastCenters,
        meanCenter: {
          x: _.meanBy(centers, ({ x }) => x),
          y: _.meanBy(centers, ({ y }) => y),
        },
      };
    }

    default:
      return state;
  }
};
